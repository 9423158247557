import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Geosuggest from './Geosuggest'; 
import './Instrution.css';
import './Geosuggest.css';
import {
    Box,
    Typography,
    TextField,
    Button,
    Container,
    Paper,
    Tooltip,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    InputLabel,
    Grid,
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Snackbar,
    Alert,
    InputAdornment,
} from '@mui/material';
import {
    Info as InfoIcon,
    PhotoCamera as PhotoCameraIcon,
    Phone as PhoneIcon,
    CheckCircle as CheckCircleIcon,
    Close as CloseIcon,
} from '@mui/icons-material';

function PerformerProfileForm() {
    const [performerData, setPerformerData] = useState({
        name: '',
        genre: [],
        image: '',
        bio: '',
        experienceLevel: '',
        preferredVenues: [],
        performanceType: '',
        full_address: '',
        preferredContactMethod: '',
        phoneNumber: ''
    });
    const [profileImagePreview, setProfileImagePreview] = useState(null);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [showError, setShowError] = useState(false);
    const [state, setState] = useState('MA');
    const [city, setCity] = useState('');

    const stateAbbreviations = [
        "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
        "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
        "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
        "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
        "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
    ];

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const instructions = {
        name: "Provide the artist or stage name you use for performances. This is what venues and fans will see.",
        image: "Upload a clear picture of yourself or your band. This image will represent your artist identity on the platform.",
        preferredContactMethod: "Enter your best contact information. Select whether you'd like to provide a phone number, cell number, or both. This will be used for receipts, reminders, and may be used for venues to reach you regarding bookings.",
        location: "Provide your primary location (city or region) so that venues in your area can find you, and you can be recommended for gigs near you.",
        performanceType: "Select whether this account will be used for a solo artist or a band. This helps venues understand your performance setup and ensures that your profile reflects whether you are performing alone or with a group.",
        experienceLevel: "Select how long you've been performing live in front of audiences, relative to the account type. If registering as a band, indicate how many years the band has been performing together.",
        genre: "Select up to three genres that best represent your music (originals or covers).",
        preferredVenues: "Choose the types of venues where you prefer to perform. Select all that apply.",
        bio: "Share a short description of your music journey, style, and key experiences."
    };

    const genres = [
        'Rock',
        'Pop',
        'Jazz',
        'Blues',
        'Hip-Hop',
        'Country',
        'EDM (Electronic Dance Music)',
        'Folk',
        'Reggae',
        'Classical',
        'Indie',
        'R&B/Soul',
        'Metal',
        'World Music',
        'Other'
    ];

    const performanceTypes = [
        'Solo Act',
        'Duo',
        'Band',
        'DJ',
        'Ensemble'
    ];

    const experienceLevels = [
        'Beginner (1-3 years)',
        'Intermediate (3-5 years)',
        'Experienced (5-10 years)',
        'Professional (10+ years)'
    ];

    const venueTypes = [
        'Small Bar (Capacity under 100)',
        'Medium Bar (Capacity 100-300)',
        'Large Bar/Club (Capacity 300+)',
        'Outdoor Venue',
        'Restaurant',
        'Café',
        'Lounge'
    ];

    const handleChange = (event) => {
        const { name, type, value, files, checked } = event.target;
        if (type === 'file') {
            const file = files[0];
            setPerformerData({ ...performerData, [name]: file });
            if (file) {
                setProfileImagePreview(URL.createObjectURL(file));
            }
        } else if (type === 'checkbox') {
            if (name === 'genre') {
                const updatedGenres = checked
                    ? [...performerData.genre, value]
                    : performerData.genre.filter(g => g !== value);
                

                
                setPerformerData({ ...performerData, genre: updatedGenres });
            } else if (name === 'preferredVenues') {
                const updatedVenues = checked
                    ? [...performerData.preferredVenues, value]
                    : performerData.preferredVenues.filter(venue => venue !== value);
                setPerformerData({ ...performerData, preferredVenues: updatedVenues });
            }
        } else {
            setPerformerData({ ...performerData, [name]: value });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        for (const key in performerData) {
            if (key === 'preferredVenues'|| key === 'genre') {
                formData.append(key, JSON.stringify(performerData[key]));
            } else {
                formData.append(key, performerData[key]);
            }
        }
        formData.append('type', 'performer');
        formData.append('full_address', performerData.full_address);
        console.log(formData);
        try {
            const response = await axios.post('https://api.live-and-local.com/profile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });
            console.log('Profile Created:', response.data);
            alert('Profile created successfully!');
            navigate('/login');
        } catch (error) {
            console.error('Failed to create profile:', error);
            alert('Failed to create profile, please check the data and try again.');
            console.error('Failed to update profile:', error.response?.data);
        }
    };
    const handleCloseSuccessDialog = () => {
        setShowSuccessDialog(false);
        navigate('/login');
    };

    useEffect(() => {
        setPerformerData(prev => ({
            ...prev,
            full_address: `${city}, ${state}`
        }));
    }, [city, state]);

    return (
        <Box sx={{
            background: 'linear-gradient(135deg, rgba(239, 97, 145, 0.05) 0%, rgba(255, 255, 255, 0.9) 100%)',
            padding: { xs: 2, sm: 4, md: 6 },
            position: 'relative',
            minHeight: '100vh',
        }}>
            <Container maxWidth="md" sx={{ position: 'relative', zIndex: 1, mt: { xs: 4, sm: 6, md: 8 } }}>
                <Paper elevation={0} sx={{
                    borderRadius: '24px',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    backdropFilter: 'blur(20px)',
                    border: '1px solid rgba(239, 97, 145, 0.1)',
                    padding: { xs: 3, sm: 4, md: 6 },
                    position: 'relative',
                    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
                }}>
                    <Box sx={{ textAlign: 'center', mb: 4 }}>
                        <Typography variant="h3" sx={{
                            fontWeight: 800,
                            background: 'linear-gradient(135deg, #EF6191 0%, #FF8FB1 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            mb: 2,
                        }}>
                            Create Performer Profile
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            Let's get started with your performer details
                        </Typography>
                    </Box>

                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                        <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Performer Name"
                                    name="name"
                                    value={performerData.name}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip title={instructions.name} arrow>
                                                <IconButton>
                                                    <InfoIcon sx={{ color: '#EF6191' }} />
                                                </IconButton>
                                            </Tooltip>
                                        ),
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#EF6191',
                                            },
                                        },
                                    }}
                                />
                            </Grid>

                            {/* Profile Image */}
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    fullWidth
                                    sx={{
                                        background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                                        color: '#fff',
                                        borderRadius: '12px',
                                        padding: '12px',
                                        textTransform: 'none',
                                        '&:hover': {
                                            background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                                            boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                                        },
                                    }}
                                    startIcon={<PhotoCameraIcon />}
                                >
                                    Upload Profile Image
                                    <input
                                        type="file"
                                        hidden
                                        name="image"
                                        onChange={handleChange}
                                        accept="image/*"
                                    />
                                </Button>
                                {profileImagePreview && (
                                    <Box sx={{ mt: 2, textAlign: 'center' }}>
                                        <Avatar
                                            src={profileImagePreview}
                                            alt="Profile Preview"
                                            sx={{ width: 100, height: 100, margin: '0 auto' }}
                                        />
                                    </Box>
                                )}
                            </Grid>

                            {/* Location */}
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    fullWidth
                                    label="City"
                                    name="city"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <InputLabel>State</InputLabel>
                                    <Select
                                        value={state}
                                        onChange={(e) => setState(e.target.value)}
                                        label="State"
                                        sx={{
                                            borderRadius: '12px',
                                        }}
                                    >
                                        {stateAbbreviations.map(st => (
                                            <MenuItem key={st} value={st}>{st}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Contact Method */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Preferred Contact Method</InputLabel>
                                    <Select
                                        name="preferredContactMethod"
                                        value={performerData.preferredContactMethod}
                                        onChange={handleChange}
                                        label="Preferred Contact Method"
                                        sx={{
                                            borderRadius: '12px',
                                        }}
                                    >
                                        <MenuItem value="email">Email</MenuItem>
                                        <MenuItem value="phone">Phone</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {performerData.preferredContactMethod === 'phone' && (
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Phone Number"
                                        name="phoneNumber"
                                        value={performerData.phoneNumber}
                                        onChange={handleChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PhoneIcon sx={{ color: '#EF6191' }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '12px',
                                            },
                                        }}
                                    />
                                </Grid>
                            )}

                            {/* Performance Type */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Performance Type</InputLabel>
                                    <Select
                                        name="performanceType"
                                        value={performerData.performanceType}
                                        onChange={handleChange}
                                        label="Performance Type"
                                        sx={{
                                            borderRadius: '12px',
                                        }}
                                    >
                                        {performanceTypes.map((type, index) => (
                                            <MenuItem key={index} value={type}>{type}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Experience Level */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Experience Level</InputLabel>
                                    <Select
                                        name="experienceLevel"
                                        value={performerData.experienceLevel}
                                        onChange={handleChange}
                                        label="Experience Level"
                                        sx={{
                                            borderRadius: '12px',
                                        }}
                                    >
                                        {experienceLevels.map((level, index) => (
                                            <MenuItem key={index} value={level}>{level}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ color: '#EF6191', mb: 2 }}>
                                    Genres
                                    <Tooltip title={instructions.genre} arrow>
                                        <IconButton>
                                            <InfoIcon sx={{ color: '#EF6191' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                                <FormGroup>
                                    <Grid container spacing={2}>
                                        {genres.map((genre, index) => (
                                            <Grid item xs={12} sm={4} key={index}>  {/* Changed from sm={6} to sm={4} */}
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={performerData.genre.includes(genre)}
                                                            onChange={handleChange}
                                                            name="genre"
                                                            value={genre}
                                                            sx={{
                                                                color: '#EF6191',
                                                                '&.Mui-checked': {
                                                                    color: '#EF6191',
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={genre}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </FormGroup>
                            </Grid>

                            {/* Preferred Venues */}
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ color: '#EF6191', mb: 2 }}>
                                    Preferred Venue Types
                                    <Tooltip title={instructions.preferredVenues} arrow>
                                        <IconButton>
                                            <InfoIcon sx={{ color: '#EF6191' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                                <FormGroup>
                                    <Grid container spacing={2}>
                                        {venueTypes.map((venue, index) => (
                                            <Grid item xs={12} sm={4} key={index}>  {/* Changed from sm={6} to sm={4} */}
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={performerData.preferredVenues.includes(venue)}
                                                            onChange={handleChange}
                                                            name="preferredVenues"
                                                            value={venue}
                                                            sx={{
                                                                color: '#EF6191',
                                                                '&.Mui-checked': {
                                                                    color: '#EF6191',
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={venue}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </FormGroup>
                            </Grid>

                            {/* Bio */}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Bio"
                                    name="bio"
                                    value={performerData.bio}
                                    onChange={handleChange}
                                    multiline
                                    rows={4}
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip title={instructions.bio} arrow>
                                                <IconButton>
                                                    <InfoIcon sx={{ color: '#EF6191' }} />
                                                </IconButton>
                                            </Tooltip>
                                        ),
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#EF6191',
                                            },
                                        },
                                    }}
                                />
                            </Grid>

                            {/* Submit Button */}
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{
                                        background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                                        color: '#fff',
                                        borderRadius: '12px',
                                        padding: '12px 24px',
                                        fontSize: '1rem',
                                        fontWeight: 600,
                                        textTransform: 'none',
                                        boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                                        '&:hover': {
                                            background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                                            boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                                            transform: 'translateY(-1px)',
                                        },
                                    }}
                                >
                                    Create Profile
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
            <Dialog
                open={showSuccessDialog}
                onClose={handleCloseSuccessDialog}
                PaperProps={{
                    sx: {
                        borderRadius: '24px',
                        padding: 2,
                    },
                }}
            >
                <DialogTitle sx={{ color: '#EF6191', fontWeight: 600 }}>
                    Profile Created Successfully
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ textAlign: 'center', mt: 2 }}>
                        <CheckCircleIcon sx={{ fontSize: 64, color: '#EF6191' }} />
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            Your performer profile has been created successfully!
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1 }}>
                            You can now log in and start using our services.
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
                    <Button
                        onClick={handleCloseSuccessDialog}
                        variant="contained"
                        sx={{
                            background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                            color: '#fff',
                            borderRadius: '12px',
                            padding: '12px 24px',
                            fontSize: '1rem',
                            fontWeight: 600,
                            textTransform: 'none',
                            boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                            '&:hover': {
                                background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                                boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                                transform: 'translateY(-1px)',
                            },
                        }}
                    >
                        Proceed to Login
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Error Snackbar */}
            <Snackbar
                open={showError}
                autoHideDuration={6000}
                onClose={() => setShowError(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setShowError(false)}
                    severity="error"
                    sx={{
                        minWidth: '300px',
                        borderRadius: '16px',
                        backgroundColor: '#EF6191',
                        color: '#fff',
                        boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                        '& .MuiAlert-icon': {
                            color: '#fff',
                        },
                        '& .MuiAlert-action': {
                            color: '#fff',
                        },
                    }}
                    icon={<CloseIcon sx={{ fontSize: '24px' }} />}
                >
                    <Box>
                        <Typography sx={{ fontWeight: 600, mb: 0.5 }}>
                            Profile Creation Failed!
                        </Typography>
                        <Typography variant="body2">
                            Please check your inputs and try again.
                        </Typography>
                    </Box>
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default PerformerProfileForm;