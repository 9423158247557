import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Grid,
  Chip,
  Paper,
  Button,
  Pagination,
  Tabs,
  Tab
} from '@mui/material';
import { 
  Event, 
  AccessTime, 
  Place, 
  MusicNote,
  AttachMoney,
  Schedule,
  Group,
  Stars,
  LocalOffer
} from '@mui/icons-material';
import ArtistContractDashboard from './ArtistBookingContract';

const ActiveBids = () => {
  const [activeBids, setActiveBids] = useState([]);
  const [bids, setBids] = useState({ live: [], expired: [] });
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stats, setStats] = useState({
    totalActiveBids: 0,
    totalExpiredBids: 0,
    totalValue: 0,
    expiredValue: 0
  });
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState(0);
  const bidsPerPage = 5;
  useEffect(() => {
    const fetchBids = async () => {
      try {
        const response = await fetch('https://api.live-and-local.com/get-active-bids', {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          }
        });
        const data = await response.json();
        
        // Separate bids into live and expired based on date
        const currentDate = new Date();
        const liveBids = data.bids.filter(bid => new Date(bid.date) >= currentDate);
        const expiredBids = data.bids.filter(bid => new Date(bid.date) < currentDate);
        
        setBids({
          live: liveBids,
          expired: expiredBids
        });
        
        // Calculate separate stats for live and expired
        setStats({
          totalActiveBids: liveBids.length,
          totalExpiredBids: expiredBids.length,
          totalValue: liveBids.reduce((sum, bid) => sum + parseFloat(bid.bidDetails.bidAmount), 0),
          expiredValue: expiredBids.reduce((sum, bid) => sum + parseFloat(bid.bidDetails.bidAmount), 0)
        });
      } catch (error) {
        console.error('Failed to fetch bids:', error);
      }
    };

    fetchBids();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPage(1); // Reset pagination when switching tabs
  };


  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  const handleBookingClick = (booking) => {
    console.log('WHAT THE FUCK')
    console.log(booking)
    setSelectedBooking(booking);
    console.log(selectedBooking)
    setIsModalOpen(true);
  };

  const handleBackToContracts = () => {
      setSelectedBooking(null);
      setIsModalOpen(false);
  };

  if (selectedBooking) {
      console.log('BEING SENT TO CONTRACT', selectedBooking);
      return <ArtistContractDashboard contract={selectedBooking } onBack={handleBackToContracts} page={'Contracts'}  />;
  }

  const currentBids = activeTab === 0 ? bids.live : bids.expired;
  const indexOfLastBid = page * bidsPerPage;
  const indexOfFirstBid = indexOfLastBid - bidsPerPage;
  const currentPageBids = currentBids.slice(indexOfFirstBid, indexOfLastBid);
  const totalPages = Math.ceil(currentBids.length / bidsPerPage);


  const StatCard = ({ title, value }) => (
    <Paper 
      elevation={0}
      sx={{
        p: 3,
        height: '100%',
        bgcolor: 'rgba(146, 74, 174, 0.04)',
        borderRadius: 2,
        border: '1px solid rgba(146, 74, 174, 0.1)',
        transform: 'translateY(0)',
        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 12px 24px -10px rgba(146, 74, 174, 0.15)',
          bgcolor: 'rgba(146, 74, 174, 0.08)',
        }
      }}
    >
      <Typography variant="body1" sx={{ color: 'text.secondary', mb: 1, fontSize: '0.875rem' }}>
        {title}
      </Typography>
      <Typography variant="h4" sx={{ color: '#924AAE', fontWeight: 500, letterSpacing: '-0.02em' }}>
        {value}
      </Typography>
    </Paper>
  );


  const BidStatusChip = ({ status, isExpired }) => {
    const getStatusColor = (status, isExpired) => {
      if (isExpired) {
        return {
          bg: 'rgba(211, 47, 47, 0.1)',
          color: 'rgb(211, 47, 47)'
        };
      }
      switch(status) {
        case 'pending':
          return {
            bg: 'rgba(34, 197, 94, 0.04)',
            color: '#EF6191'
          };
        case 'accepted':
          return {
            bg: 'rgba(46, 125, 50, 0.1)',
            color: 'rgb(46, 125, 50)'
          };
        case 'declined':
          return {
            bg: 'rgba(211, 47, 47, 0.1)',
            color: 'rgb(211, 47, 47)'
          };
        default:
          return {
            bg: 'rgba(34, 97, 94, 0.04)',
            color: '#EF6191'
          };
      }
    };

    const colors = getStatusColor(status, isExpired);
    const displayStatus = isExpired ? "Expired" : status.charAt(0).toUpperCase() + status.slice(1);

    return (
      <Chip 
        label={displayStatus}
        size="small"
        sx={{ 
          bgcolor: colors.bg,
          color: colors.color,
          border: 'none',
          borderRadius: '6px',
          height: '24px',
          '& .MuiChip-label': {
            px: 1.5,
            fontSize: '0.75rem',
            fontWeight: 500
          }
        }}
      />
    );
  };

  const BidCard = ({ bid, isExpired = false }) => {
    const isPastBid = new Date(bid.date) < new Date();
  
    const getHoverStyles = () => {
      if (isPastBid) {
        return {
          transform: 'none',
          boxShadow: '0 12px 24px -10px rgba(239, 68, 68, 0.2)',
          borderColor: 'rgba(239, 68, 68, 0.3)',
          bgcolor: 'rgba(239, 68, 68, 0.02)',
        };
      }
      return {
        transform: 'translateY(-4px)',
        boxShadow: '0 12px 24px -10px rgba(34, 197, 94, 0.2)',
        borderColor: 'rgba(34, 197, 94, 0.3)',
        bgcolor: 'rgba(34, 197, 94, 0.02)',
      };
    };
    return (
      <Paper 
        elevation={0}
        sx={{
          mb: 2,
          borderRadius: 2,
          border: '1px solid rgba(0, 0, 0, 0.08)',
          overflow: 'hidden',
          opacity: isPastBid ? 0.85 : 1,
          transform: 'translateY(0)',
          transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
          cursor: 'pointer',
          position: 'relative',
          borderLeft: isPastBid ? '4px solid rgb(239, 68, 68)' : '4px solid rgb(34, 197, 94)',
          '&:hover': getHoverStyles()
        }}
      >
        <Box sx={{ p: 3 }}>
          {/* Header */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography 
            variant="h5" 
            sx={{ 
              fontWeight: 500,
              color: isPastBid ? 'rgb(239, 68, 68)' : '#EF6191',
              letterSpacing: '-0.02em'
            }}
          >
            {bid.profile_name}
          </Typography>
          <BidStatusChip status={bid.status} isExpired={isPastBid} />
        </Box>


        <Grid container spacing={4} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Event sx={{ 
                color: isPastBid ? 'rgb(239, 68, 68)' : '#EF6191', 
                mr: 1.5, 
                fontSize: 20 
              }} />
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {new Date(bid.date).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric'
                })}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AccessTime sx={{ 
                color: isPastBid ? 'rgb(239, 68, 68)' : '#EF6191', 
                mr: 1.5, 
                fontSize: 20 
              }} />
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {bid.startTime} - {bid.endTime}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Place sx={{ 
                color: isPastBid ? 'rgb(239, 68, 68)' : '#EF6191', 
                mr: 1.5, 
                fontSize: 20 
              }} />
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {bid.location}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <MusicNote sx={{ 
                color: isPastBid ? 'rgb(239, 68, 68)' : '#EF6191', 
                mr: 1.5, 
                fontSize: 20 
              }} />
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {bid.details.performanceType}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Bid Details */}
        <Box sx={{ 
            p: 2, 
            bgcolor: isPastBid ? 'rgba(239, 68, 68, 0.04)' : 'rgba(34, 197, 94, 0.04)', 
            borderRadius: 1, 
            mb: 3 
          }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <AttachMoney sx={{ color: '#EF6191', mr: 1.5, fontSize: 20 }} />
                  <Box>
                    <Typography variant="body2" color="text.secondary">Your Bid</Typography>
                    <Typography variant="subtitle1" fontWeight={500}>
                      ${bid.bidDetails.bidAmount}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Stars sx={{ color: '#EF6191', mr: 1.5, fontSize: 20 }} />
                  <Box>
                    <Typography variant="body2" color="text.secondary">Experience Level</Typography>
                    <Typography variant="subtitle1" fontWeight={500}>
                      {bid.details.experienceLevel}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Group sx={{ color: '#EF6191', mr: 1.5, fontSize: 20 }} />
                  <Box>
                    <Typography variant="body2" color="text.secondary">Venue Capacity</Typography>
                    <Typography variant="subtitle1" fontWeight={500}>
                      {bid.venueDetails.capacity}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <LocalOffer sx={{ color: '#EF6191', mr: 1.5, fontSize: 20 }} />
                  <Box>
                    <Typography variant="body2" color="text.secondary">Price Range</Typography>
                    <Typography variant="subtitle1" fontWeight={500}>
                      ${bid.details.priceRange.min} - ${bid.details.priceRange.max}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Actions */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, pt: 2, borderTop: '1px solid rgba(0, 0, 0, 0.06)' }}>
            <Button 
              variant="outlined" 
              sx={{ 
                color: isPastBid ? 'rgb(239, 68, 68)' : 'rgb(34, 197, 94)',
                borderColor: isPastBid ? 'rgb(239, 68, 68)' : 'rgb(34, 197, 94)',
                '&:hover': {
                  borderColor: isPastBid ? 'rgb(239, 68, 68)' : 'rgb(34, 197, 94)',
                  bgcolor: isPastBid ? 'rgba(239, 68, 68, 0.04)' : 'rgba(34, 197, 94, 0.04)'
                }
              }}
              onClick={() => handleBookingClick(bid)}
            >
              View Details
            </Button>
          </Box>
        </Box>
      </Paper>
    )
  };

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', p: { xs: 2, sm: 3, md: 4 }, bgcolor: '#ffffff' }}>
      {/* Header */}
      <Box sx={{ mb: 6, maxWidth: 720 }}>
        <Typography variant="h3" sx={{ mb: 2, fontWeight: 600, color: 'text.primary', letterSpacing: '-0.02em' }}>
          Bid Management
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary', lineHeight: 1.6 }}>
          Track and manage your venue booking proposals
        </Typography>
      </Box>

      {/* Tabs */}
      <Tabs 
        value={activeTab} 
        onChange={handleTabChange}
        sx={{ 
          mb: 4,
          '& .MuiTab-root': {
            color: '#924AAE',
            '&.Mui-selected': {
              color: '#924AAE',
              fontWeight: 600
            }
          },
          '& .MuiTabs-indicator': {
            backgroundColor: '#924AAE'
          }
        }}
      >
        <Tab 
          label={`Live Bids (${bids.live.length})`}
          sx={{ textTransform: 'none' }}
        />
        <Tab 
          label={`Expired Bids (${bids.expired.length})`}
          sx={{ textTransform: 'none' }}
        />
	    </Tabs>
	       <Grid container spacing={3} sx={{ mb: 6 }}>
        <Grid item xs={12} sm={6}>
          <StatCard 
            title={activeTab === 0 ? "Active Bids" : "Expired Bids"}
            value={activeTab === 0 ? stats.totalActiveBids : stats.totalExpiredBids}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StatCard 
            title="Total Value"
            value={`$${(activeTab === 0 ? stats.totalValue : stats.expiredValue).toLocaleString()}`}
          />
        </Grid>
      </Grid>

      {/* Bids List */}
      <Box>
        {currentPageBids.length === 0 ? (
          <Typography 
            textAlign="center" 
            color="text.secondary" 
            sx={{ py: 8 }}
          >
            {activeTab === 0 
              ? "No active bids at the moment."
              : "No expired bids to display."}
          </Typography>
        ) : (
          currentPageBids.map((bid) => (
            <BidCard 
              key={bid.id} 
              bid={bid} 
              isExpired={activeTab === 1}
            />
          ))
        )}
      </Box>

      {/* Pagination */}
      {totalPages > 1 && (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          mt: 4, 
          mb: 2 
        }}>
          <Pagination 
            count={totalPages} 
            page={page} 
            onChange={handlePageChange}
            size="large"
            sx={{
              '& .MuiPaginationItem-root': {
                color: '#924AAE',
                '&:hover': {
                  bgcolor: 'rgba(146, 74, 174, 0.04)',
                },
              },
              '& .MuiPaginationItem-page.Mui-selected': {
                bgcolor: '#924AAE',
                color: 'white',
                '&:hover': {
                  bgcolor: '#7a3b91',
                },
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ActiveBids;