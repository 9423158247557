import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  InputAdornment,
  Snackbar,
  Alert,
  Container
} from '@mui/material';
import { 
  Event, 
  AttachMoney, 
  Schedule,
  Place,
  CheckCircle,
  Edit as EditIcon,
  Close as CloseIcon,
  MusicNote
} from '@mui/icons-material';

const ArtistContractDashboard = ({ contract, onBack, page }) => {
  console.log('THE CONTRACT HERE', contract)
  // All hooks at the top level
  const [openSignedContract, setOpenSignedContract] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
    const [isCancelling, setIsCancelling] = useState(false);
  const [editForm, setEditForm] = useState({
    bid_amount: '',
    artist_notes: '',
    technical_requirements: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!contract) {
    return <div>Loading...</div>;
  }
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccessSnackbar(false);
  };

  const handleOpenEdit = () => {
    console.log(contract)
    console.log('CONTRACT IN TEH BIUD');
    setEditForm({
      bid_amount: contract.bid_amount ?? contract?.contract?.bid_amount ?? contract?.bidDetails?.bidAmount ?? contract?.contract?.bidDetails?.bidAmount ?? '',
      artist_notes: contract.artist_notes ?? contract?.contract?.artist_notes ?? '',
      technical_requirements: contract.technical_requirements ?? contract?.contract?.technical_requirements ?? '',
    });
    setOpenEditDialog(true);
  };

  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const handleEditChange = (field) => (event) => {
    setEditForm(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleSubmitEdit = async () => {
    setIsSubmitting(true);
    console.log(contract.id);
    console.log(contract.contract_id);

    try {
      const response = await fetch('https://api.live-and-local.com/api/update-contract', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          contractId: contract.contract_id ?? contract?.contract?.id ?? contract.id ?? '',
          bidAmount: editForm.bid_amount ||contractDetails.fee,
          technicalRequirements: editForm.technical_requirements,
          artistNotes: editForm.artist_notes,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update contract');
      }

      const data = await response.json();
      console.log('Contract updated successfully:', data);
      handleCloseEdit();
      setOpenSuccessSnackbar(true); // Show success notification

    } catch (error) {
      console.error('Error updating contract:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleViewSignedCopy = () => {
    setOpenSignedContract(true);
  };

  const handleCloseSignedCopy = () => {
    setOpenSignedContract(false);
  };
  console.log('CONTRACT BEFORE THE CONTRACT DETAILS', contract)
  const contractDetails = {
    id: contract.id ?? contract?.contract?.id,
    artist: contract.artist ?? contract?.contract?.artist,
    event: contract.event ?? contract?.contract?.event,
    date: contract.date ?? contract?.contract?.date,
    status: contract.status,
    location: contract.location ?? contract?.contract?.booking_details?.location ?? '',
    venue: contract.name ?? contract.profile_name ?? contract?.booking_details?.venueName ?? contract?.contract?.booking_details?.venueName ?? '',
    fee: contract.bid_amount || contract?.contract?.bid_amount || (contract.bidDetails?.bidAmount) || '',
    duration: "2 hours",
    equipment: contract.technical_requirements ?? contract?.contract?.technical_requirements,
    specialRequirements: contract.artist_notes ?? contract?.contract?.artist_notes,
    contractTerms: [
      `Experience Level: ${capitalizeFirst(
        // If contract.details exists, use its experienceLevel, otherwise fallback to root level
        contract.details?.experienceLevel ?? contract.experienceLevel ?? ''
      )}`,
      `Genre: ${
        // Try contract.details.genres first, then contract.genre
        (contract.details?.genres?.join(", ") ?? contract.genre ?? '')
      }`,
      `Performance Type: ${capitalizeFirst(
        // Try contract.details.performanceType first, then contract.performerType
        contract.details?.performanceType ?? contract.performerType ?? ''
      )}`,
      `Minimum Price: $${
        // Try contract.details.priceRange.min first, then contract.minPrice
        contract.details?.priceRange?.min ?? contract.minPrice ?? ''
      }`,
    ],
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'signed':
      case 'booked':
        return 'success';
      case 'pending':
        return 'warning';
      default:
        return 'default';
    }
  };

  function capitalizeFirst(str) {
    if (!str) return '';
    // Uppercase the first character, then append the rest unchanged
    return str[0].toUpperCase() + str.slice(1);
  }

  const isContractConfirmed = contractDetails.status.toLowerCase() === 'signed' || 
                             contractDetails.status.toLowerCase() === 'booked';


  console.log(contractDetails);


    
    const handleOpenCancel = () => {
        setOpenCancelDialog(true);
    };
    
    const handleCloseCancel = () => {
        setOpenCancelDialog(false);
        setCancelReason('');
    };

    const handleCancelContract = async () => {
      setIsCancelling(true);
      console.log('THE ID GOING TO THE BACKEND; ',contract.contract_id ?? contract?.contract?.id ?? contract.id ?? '' )
      try {
        const response = await fetch('https://api.live-and-local.com/api/cancel-contract', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            contractId: contract.contract_id ?? contract?.contract?.id ?? contract.id ?? '',
            reason: cancelReason
          }),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to cancel contract');
        }
  
        const data = await response.json();
        handleCloseCancel();
        setOpenSuccessSnackbar(true);
        // You might want to trigger a refresh or navigation here
        if (onBack) onBack(); // Navigate back after successful cancellation
  
      } catch (error) {
        console.error('Error cancelling contract:', error);
        // Handle error (show error message to user)
      } finally {
        setIsCancelling(false);
      }
    };
  



  return (
    <Box className="min-h-screen" sx={{
      background: 'linear-gradient(135deg, rgba(239, 97, 145, 0.05) 0%, rgba(255, 255, 255, 0.9) 100%)',
      padding: { xs: 2, sm: 4, md: 6 },
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '300px',
        background: 'linear-gradient(180deg, rgba(239, 97, 145, 0.08) 0%, rgba(255, 255, 255, 0) 100%)',
        zIndex: 0,
      }
    }}>
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Button 
          onClick={onBack} 
          variant="outlined" 
          sx={{ 
            mb: 4,
            borderRadius: '12px',
            borderColor: '#EF6191',
            color: '#EF6191',
            '&:hover': {
              borderColor: '#FF8FB1',
              backgroundColor: 'rgba(239, 97, 145, 0.05)'
            }
          }}
        >
          Back to {page}
        </Button>

        <Paper 
          elevation={0}
          sx={{
            borderRadius: '24px',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(20px)',
            border: '1px solid rgba(239, 97, 145, 0.1)',
            padding: { xs: 3, sm: 4, md: 6 },
            mb: 4,
            position: 'relative',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '6px',
              background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
              borderRadius: '24px 24px 0 0'
            }
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Typography 
                variant="h3" 
                sx={{
                  fontWeight: 800,
                  background: 'linear-gradient(135deg, #EF6191 0%, #FF8FB1 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  mb: 2
                }}
              >
                {contractDetails.venue}
              </Typography>
              <Typography variant="h6" color="text.secondary" sx={{ mb: 3 }}>
                {contractDetails.location}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
              <Chip 
                label={contractDetails.status} 
                color={getStatusColor(contractDetails.status)}
                sx={{
                  fontSize: '1rem',
                  padding: '20px 16px',
                  borderRadius: '12px',
                  fontWeight: 600,
                  backgroundColor: getStatusColor(contractDetails.status) === 'success' ? '#EF6191' : undefined,
                  '& .MuiChip-label': {
                    padding: '0 8px'
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3, 
                  height: '100%',
                  borderRadius: '16px',
                  backgroundColor: 'rgba(239, 97, 145, 0.05)',
                  border: '1px solid rgba(239, 97, 145, 0.1)'
                }}
              >
                <Typography variant="h6" sx={{ mb: 3, color: '#EF6191', fontWeight: 600 }}>
                  Event Details
                </Typography>
                <List>
                  <ListItem sx={{ px: 0 }}>
                    <Event sx={{ mr: 2, color: '#EF6191' }} />
                    <ListItemText 
                      primary={<Typography sx={{ fontWeight: 600 }}>Date</Typography>}
                      secondary={contractDetails.date}
                    />
                  </ListItem>
                  <ListItem sx={{ px: 0 }}>
                    <Schedule sx={{ mr: 2, color: '#EF6191' }} />
                    <ListItemText 
                      primary={<Typography sx={{ fontWeight: 600 }}>Duration</Typography>}
                      secondary={contractDetails.duration}
                    />
                  </ListItem>
                  <ListItem sx={{ px: 0 }}>
                    <Place sx={{ mr: 2, color: '#EF6191' }} />
                    <ListItemText 
                      primary={<Typography sx={{ fontWeight: 600 }}>Venue</Typography>}
                      secondary={contractDetails.venue}
                    />
                  </ListItem>
                  <ListItem sx={{ px: 0 }}>
                    <AttachMoney sx={{ mr: 2, color: '#EF6191' }} />
                    <ListItemText 
                      primary={<Typography sx={{ fontWeight: 600 }}>Fee</Typography>}
                      secondary={`$${contractDetails.fee}`}
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3, 
                  height: '100%',
                  borderRadius: '16px',
                  backgroundColor: 'rgba(239, 97, 145, 0.05)',
                  border: '1px solid rgba(239, 97, 145, 0.1)'
                }}
              >
                <Typography variant="h6" sx={{ mb: 3, color: '#EF6191', fontWeight: 600 }}>
                  Performance Requirements
                </Typography>
                <List>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemText 
                      primary={<Typography sx={{ fontWeight: 600 }}>Equipment</Typography>}
                      secondary={contractDetails.equipment || "No equipment specified"}
                    />
                  </ListItem>
                  <ListItem sx={{ px: 0 }}>
                    <ListItemText 
                      primary={<Typography sx={{ fontWeight: 600 }}>Special Requirements</Typography>}
                      secondary={contractDetails.specialRequirements || "No special requirements"}
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper 
              elevation={0}
              sx={{ 
                p: 3, 
                borderRadius: '16px',
                backgroundColor: 'rgba(239, 97, 145, 0.05)',
                border: '1px solid rgba(239, 97, 145, 0.1)',
                mt: 4
              }}
            >
              <Typography variant="h6" sx={{ mb: 3, color: '#EF6191', fontWeight: 600 }}>
                Venue Terms
              </Typography>
              <List sx={{ 
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                borderRadius: '12px',
                overflow: 'hidden'
              }}>
                {contractDetails.contractTerms.map((term, index) => (
                  <React.Fragment key={index}>
                    <ListItem 
                      sx={{ 
                        px: 3,
                        py: 2,
                        '&:hover': {
                          backgroundColor: 'rgba(239, 97, 145, 0.03)'
                        }
                      }}
                    >
                      <ListItemText 
                        primary={
                          <Typography sx={{ fontWeight: 500 }}>
                            {term}
                          </Typography>
                        }
                      />
                    </ListItem>
                    {index < contractDetails.contractTerms.length - 1 && (
                      <Divider sx={{ 
                        borderColor: 'rgba(239, 97, 145, 0.1)',
                        margin: '0 24px'
                      }} />
                    )}
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>

          <Box sx={{ mt: 4, display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
            {!isContractConfirmed && (
              <Button 
                variant="contained" 
                onClick={handleOpenEdit}
                sx={{
                  background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                  color: '#fff',
                  borderRadius: '12px',
                  padding: '12px 24px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textTransform: 'none',
                  boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                  '&:hover': {
                    background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                    boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                    transform: 'translateY(-1px)'
                  }
                }}
              >
                Edit Contract
              </Button>
            )}
             {isContractConfirmed && (
              <>
                <Button 
                  variant="outlined"
                  color="error"
                  onClick={handleOpenCancel}
                  sx={{
                    borderRadius: '12px',
                    padding: '12px 24px',
                    borderColor: '#EF6191',
                    color: '#EF6191',
                    '&:hover': {
                      borderColor: '#FF8FB1',
                      backgroundColor: 'rgba(239, 97, 145, 0.05)'
                    }
                  }}
                >
                  Cancel Contract
                </Button>
                <Button 
                  variant="contained" 
                  color="secondary"
                  onClick={handleViewSignedCopy}
                  sx={{
                    background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                    color: '#fff',
                    borderRadius: '12px',
                    padding: '12px 24px'
                  }}
                >
                  View Signed Copy
                </Button>
              </>
            )}
          </Box>
        </Paper>

        <Dialog
          open={openCancelDialog}
          onClose={handleCloseCancel}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            sx: {
              borderRadius: '24px',
              padding: 2
            }
          }}
        >
          <DialogTitle sx={{ color: '#EF6191', fontWeight: 600 }}>
            Cancel Contract
            <IconButton
              onClick={handleCloseCancel}
              sx={{
                position: 'absolute',
                right: 16,
                top: 16,
                color: '#EF6191'
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ mt: 2, mb: 3 }}>
              Are you sure you want to cancel this contract? This action cannot be undone.
            </Typography>
            <TextField
              fullWidth
              label="Reason for Cancellation"
              multiline
              rows={4}
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
              sx={{ 
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#EF6191'
                  }
                }
              }}
            />
          </DialogContent>
          <DialogActions sx={{ padding: 3 }}>
            <Button 
              onClick={handleCloseCancel}
              sx={{
                borderRadius: '12px',
                padding: '12px 24px'
              }}
            >
              Back
            </Button>
            <Button 
              onClick={handleCancelContract}
              variant="contained"
              color="error"
              disabled={isCancelling}
              sx={{
                borderRadius: '12px',
                padding: '12px 24px',
                backgroundColor: '#EF6191',
                '&:hover': {
                  backgroundColor: '#FF8FB1'
                }
              }}
            >
              {isCancelling ? 'Cancelling...' : 'Confirm Cancellation'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialogs and Snackbar remain largely the same, with updated styling */}
        <Dialog
          open={openEditDialog}
          onClose={handleCloseEdit}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            sx: {
              borderRadius: '24px',
              padding: 2
            }
          }}
        >
          <DialogTitle sx={{ color: '#EF6191', fontWeight: 600 }}>
            Edit Contract Details
            <IconButton
              onClick={handleCloseEdit}
              sx={{
                position: 'absolute',
                right: 16,
                top: 16,
                color: '#EF6191'
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="Bid Amount"
                type="number"
                value={editForm.bid_amount}
                onChange={handleEditChange('bid_amount')}
                sx={{ 
                  mb: 3,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#EF6191'
                    }
                  }
                }}
              />
            
            <TextField
              fullWidth
              label="Technical Requirements"
              multiline
              rows={4}
              value={editForm.technical_requirements}
              onChange={handleEditChange('technical_requirements')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EditIcon color="action" />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              label="Artist Notes"
              multiline
              rows={4}
              value={editForm.artist_notes}
              onChange={handleEditChange('artist_notes')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EditIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
            
          </DialogContent>
          <DialogActions sx={{ padding: 3 }}>
            <Button 
              onClick={handleSubmitEdit} 
              variant="contained"
              disabled={isSubmitting}
              sx={{
                background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                borderRadius: '12px',
                padding: '12px 24px'
              }}
            >
              {isSubmitting ? 'Saving...' : 'Save Changes'}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
        open={openSignedContract}
        onClose={handleCloseSignedCopy}
        fullWidth
        maxWidth="md"
        aria-labelledby="signed-contract-dialog-title"
      >
        <DialogTitle id="signed-contract-dialog-title">
          Signed Contract
          <IconButton
            aria-label="close"
            onClick={handleCloseSignedCopy}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            {/* You can add a close icon here if desired */}
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                Artist: {contract.artistName}
                <CheckCircle color="success" sx={{ ml: 1 }} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                Venue: {contractDetails.venue}
                <CheckCircle color="success" sx={{ ml: 1 }} />
              </Typography>
            </Grid>
          </Grid>

          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>Event Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>Date: {contractDetails.date}</Typography>
                <Typography>Duration: {contractDetails.duration}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Location: {contract.location}</Typography>
                <Typography>Fee: {contractDetails.fee}</Typography>
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>Performance Requirements</Typography>
            <Typography>Equipment: {contractDetails.equipment}</Typography>
            <Typography>Special Requirements: {contractDetails.specialRequirements}</Typography>
          </Paper>

          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Contract Terms</Typography>
            <List>
              {contractDetails.contractTerms.map((term, index) => (
                <ListItem key={index}>
                  <ListItemText primary={term} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSignedCopy} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

        <Snackbar
          open={openSuccessSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={handleCloseSnackbar} 
            severity="success"
            sx={{ 
              minWidth: '300px',
              borderRadius: '16px',
              backgroundColor: '#EF6191',
              color: '#fff',
              boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
              '& .MuiAlert-icon': {
                color: '#fff'
              },
              '& .MuiAlert-action': {
                color: '#fff'
              }
            }}
            icon={<MusicNote sx={{ fontSize: '24px' }} />}
          >
            <Box>
              <Typography sx={{ fontWeight: 600, mb: 0.5 }}>
                Contract Updated!
              </Typography>
              <Typography variant="body2">
                Your changes have been successfully saved
              </Typography>
            </Box>
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default ArtistContractDashboard;