import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia,
  Container,
  IconButton,
  styled,
  createTheme,
  ThemeProvider
} from '@mui/material';
import { 
  AddCircle, 
  ViewList, 
  Info, 
  CalendarToday,
  NotificationsNone,
  EventAvailable,
  AccessTime
} from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import BookingImage from './87730da1-059c-4dd5-aad5-e2bbdfc061d4.webp';

// Create custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#EF6191',
    },
    text: {
      primary: '#000000',
      secondary: '#666666',
    },
  },
});

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[8],
  },
}));

const QuickActionCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: '#f5f5f7',
  height: '100%',
  '&:hover': {
    backgroundColor: '#e8e8e8',
    cursor: 'pointer',
  },
  '& .MuiIconButton-root': {
    color: theme.palette.primary.main,
  },
}));

const BrandedTypography = styled(Typography)(({ theme }) => ({
  '&.brand-heading': {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

const BookingDetailsCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(3),
  marginTop: theme.spacing(2),
  backgroundColor: '#ffffff',
  boxShadow: theme.shadows[3],
}));

const ArtistBookingLandingPage = ({ onFindEvent, onViewBookings, setCurrentPage  }) => {
  const [pendingBids, setPendingBid] = useState([]);
  const [bookedBookings, setBookedBookings] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const [activeBidsCount, setActiveBidsCount] = useState(0);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.post('https://api.live-and-local.com/getArtistBookings', {}, { withCredentials: true });
        const fetchedBookings = response.data;
        
        // Filter pending bookings
        const pendingBookings = fetchedBookings.filter(booking => booking.status === 'Pending');
        setPendingBid(pendingBookings);
        
        // Calculate active bids count (future dates only)
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time to start of day
        
        const activeBids = pendingBookings.filter(booking => {
          const bookingDate = new Date(booking.date);
          return bookingDate >= today;
        });
        console.log(activeBids)
        setActiveBidsCount(activeBids.length);
        setBookedBookings(fetchedBookings.filter(booking => booking.status === 'Booked'));
      } catch (error) {
        console.error('Error fetching bookings data:', error);
      }
    };
    fetchBookings();
  }, []);

  const bookedDates = bookedBookings
    .map(booking => ({
      date: booking.parsedDate,
      isSigned: booking.artist_signed
    }))
    .filter(booking => booking.date !== null);

  const pendingDates = pendingBids
    .map(bid => bid.parsedDate)
    .filter(date => date !== null);

  const handleDateClick = (date) => {
    const booked = bookedBookings.filter(booking => 
      new Date(booking.date).toDateString() === date.toDateString()
    );
    const pending = pendingBids.filter(bid => 
      new Date(bid.date).toDateString() === date.toDateString()
    );
    setSelectedData([...booked, ...pending]);
    setSelectedDate(date);
  };
  console.log(activeBidsCount)
  const quickActions = [
    {
      icon: <AddCircle />,
      title: 'Find Events',
      description: 'Discover new performance opportunities',
      action: onFindEvent
    },
    {
      icon: <ViewList />,
      title: 'View Bookings',
      description: 'Manage your schedule',
      action: onViewBookings
    },
    {
      icon: <NotificationsNone />,
      title: 'Pending Bids',
      description: `${activeBidsCount} active bids`,
      action: () => setCurrentPage('activebids')
    },
    {
      icon: <EventAvailable />,
      title: 'Confirmed Gigs',
      description: 'View confirmed gigs',
      action: () => setCurrentPage('confirmedGigs')
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ py: 6 }}>
        {/* Header Section */}
        <Box sx={{ mb: 8, textAlign: 'center' }}>
          <BrandedTypography variant="h3" className="brand-heading" gutterBottom>
            Artist Booking Dashboard
          </BrandedTypography>
          <Typography variant="h5" color="text.secondary" sx={{ mb: 6 }}>
            Discover opportunities and manage your performance schedule with ease.
          </Typography>
        </Box>

        {/* Quick Actions Grid */}
        <Grid container spacing={4} sx={{ mb: 8 }}>
          {quickActions.map((action, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <QuickActionCard onClick={action.action}>
                <CardContent sx={{ textAlign: 'center', py: 4 }}>
                  <IconButton sx={{ mb: 2, p: 2, backgroundColor: 'rgba(239, 97, 145, 0.1)' }}>
                    {action.icon}
                  </IconButton>
                  <Typography variant="h6" gutterBottom>
                    {action.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {action.description}
                  </Typography>
                </CardContent>
              </QuickActionCard>
            </Grid>
          ))}
        </Grid>

        {/* Calendar and Info Section */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <StyledCard sx={{ height: '100%' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CalendarToday color="primary" />
                  Booking Calendar
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center',
                  width: '100%'
                }}>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateClick}
                    inline
                    highlightDates={pendingDates}
                    className="date-picker"
                    holidays={bookedDates}
                  />
                </Box>
                {selectedData && selectedData.length > 0 && (
                  <BookingDetailsCard>
                    <Typography variant="h6" gutterBottom>
                      Date Details
                    </Typography>
                    {selectedData.map((booking, index) => (
                      <Box key={index} sx={{ mb: 2 }}>
                        {booking.name && (
                          <Typography variant="subtitle2" color="text.secondary">
                            Venue: <span style={{ color: '#000' }}>{booking.name}</span>
                          </Typography>
                        )}
                        {booking.status && (
                          <Typography variant="subtitle2" color="text.secondary">
                            Status: <span style={{ color: '#000' }}>{booking.status}</span>
                          </Typography>
                        )}
                        {booking.status === "Booked" && (
                          <Typography variant="subtitle2" color="text.secondary">
                            Signed Contract: <span style={{ color: '#000' }}>{booking.artist_signed === 1 ? "Yes" : "No"}</span>
                          </Typography>
                        )}
                        {index < selectedData.length - 1 && (
                          <Box sx={{ my: 1, borderBottom: '1px solid #eee' }} />
                        )}
                      </Box>
                    ))}
                  </BookingDetailsCard>
                )}
              </CardContent>
            </StyledCard>
          </Grid>

          <Grid item xs={12} md={6}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Info color="primary" />
                  Why Choose Our Booking System?
                </Typography>
                <CardMedia
                  component="img"
                  sx={{ 
                    height: 200, 
                    borderRadius: 2,
                    mb: 3
                  }}
                  image={BookingImage}
                  alt="Booking System"
                />
                <Typography variant="body1" color="text.secondary">
                  Our platform makes it easy to find and secure performance opportunities tailored to your genre
                  and location preferences. Connect directly with venues, manage your bookings, and grow your 
                  career through exposure to new audiences.
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default ArtistBookingLandingPage;