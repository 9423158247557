import React, { useState, useEffect  } from 'react';
import { 
  Box, Typography, TextField, Button, List, ListItem, 
  ListItemText, ListItemAvatar, Avatar, IconButton, Chip, 
  Grid, Card, CardContent, CardActions, Divider, Tabs, Tab
} from '@mui/material';
import { 
  Search, FilterList, MoreVert, Event, AccessTime, Place, CheckCircle, Info
} from '@mui/icons-material';
import { useUser } from '../context/UserContext'; 
import './BookingStylesImp.css'; // Reusing the same styles

const BookingsList = ({ setSelectedBooking, setCurrentPage, profile_id }) => {
    const [bookings, setBookings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useUser();
    const [activeTab, setActiveTab] = useState(0);
    
    console.log(profile_id)
    useEffect(() => {
      const fetchBookings = async () => {
          try {
              const response = await fetch(`https://api.live-and-local.com/impget-bookings/${profile_id}`);
              if (!response.ok) {
                  throw new Error('Failed to fetch bookings');
              }
              const data = await response.json();
              console.log(data);
              setBookings(data);
              console.log(bookings)
              setIsLoading(false);
          } catch (error) {
              console.error('Error fetching bookings:', error);
              setError(error.message);
              setIsLoading(false);
          }
      };

      fetchBookings();
  }, []);
  const currentDate = new Date();
  const activeBookings = bookings.filter(booking => new Date(booking.date) >= currentDate);
  const expiredBookings = bookings.filter(booking => new Date(booking.date) < currentDate);

  // Categorize Active Bookings
  const availableBookings = activeBookings.filter(booking => booking.status === 'available');
  const interestedBookings = activeBookings.filter(booking => booking.status === 'interest_shown');
  const bookedBookings = activeBookings.filter(booking => booking.status === 'booked');

  // Categorize Expired Bookings
  const expiredAvailableBookings = expiredBookings.filter(booking => booking.status === 'available');
  const expiredInterestedBookings = expiredBookings.filter(booking => booking.status === 'interest_shown');
  const expiredBookedBookings = expiredBookings.filter(booking => booking.status === 'booked');
    console.log(availableBookings);
    console.log(interestedBookings);
    const getStatusChip = (status) => {
        let color;
        switch(status) {
          case 'available':
            color = 'success';
            break;
          case 'interest_shown':
            color = 'primary';
            break;
          case 'booked':
            color = 'error';
            break;
          default:
            color = 'default';
        }
        return <Chip label={status} color={color} size="small" />;
      };
  
    const getActionIcon = (status) => {
        switch(status) {
          case 'Available':
            return <Info />;
          case 'With Interest':
            return <CheckCircle />;
          case 'Booked':
            return <MoreVert />;
          default:
            return null;
        }
      };
    
      const renderBookingRow = (booking) => (
        <ListItem 
          key={booking.id} 
          className="booking-row" 
          sx={{ paddingY: 1 }} 
          onClick={() => {
            setSelectedBooking(booking);
            setCurrentPage('viewSingleBooking'); // Change page to single booking view
          }}
        >

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">{new Date(booking.date).toLocaleDateString()}</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">{`${booking.startTime} - ${booking.endTime}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">{booking.details.performanceType}</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">{booking.details.genres.join(', ')}</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">${booking.details.priceRange.min} - ${booking.details.priceRange.max}</Typography>
            </Grid>
            <Grid item xs={12} sm={1}>
              {getStatusChip(booking.status)}
            </Grid>
            <Grid item xs={12} sm={1}>
              <IconButton size="small">
                {getActionIcon(booking.status)}
              </IconButton>
            </Grid>
          </Grid>
          {(booking.interestedArtists?.length > 0 || booking.bookedBy) && (
            <Box sx={{ mt: 1, ml: 2, width: '100%' }}>
              <Typography variant="caption" color="text.secondary">
                {booking.interestedArtists?.length > 0 
                  ? `Interested Artists: ${booking.interestedArtists.map(artist => artist.name).join(', ')}`
                  : `Booked By: ${booking.bookedBy.name}`
                }
              </Typography>
            </Box>
          )}
          <Divider sx={{ mt: 1 }} />
        </ListItem>
      );
    
      return (
        <Box className="bookings-list-page" sx={{ p: { xs: 2, sm: 3 }, backgroundColor: '#f9f9f9' }}>
          {/* Header */}
          <Box className="bookings-header" sx={{ mb: 4, textAlign: 'center' }}>
              <Typography variant="h4" gutterBottom>Your Bookings</Typography>
              <Typography variant="body1" color="text.secondary">
                  Total Bookings: {bookings.length}
              </Typography>
          </Box>

          <Tabs 
              value={activeTab} 
              onChange={(e, newValue) => setActiveTab(newValue)}
              sx={{ mb: 4 }}
          >
              <Tab 
                  label={`Active Bookings (${activeBookings.length})`}
                  sx={{ color: '#EF6191' }}
              />
              <Tab 
                  label={`Expired Bookings (${expiredBookings.length})`}
                  sx={{ color: '#EF6191' }}
              />
          </Tabs>
  
          {/* Column Headers */}
          <Box sx={{ mb: 2 }}>
            <Grid container spacing={2} alignItems="center" sx={{ backgroundColor: '#e0e0e0', paddingY: 1, borderRadius: 1 }}>
              <Grid item xs={12} sm={2}>
                <Typography variant="subtitle1" fontWeight="bold">Date</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="subtitle1" fontWeight="bold">Time</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="subtitle1" fontWeight="bold">Performance Type</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="subtitle1" fontWeight="bold">Genres</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="subtitle1" fontWeight="bold">Price Range</Typography>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="subtitle1" fontWeight="bold">Status</Typography>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="subtitle1" fontWeight="bold">Actions</Typography>
              </Grid>
            </Grid>
          </Box>
  
          {activeTab === 0 ? (
                // Active Bookings Content
                <>
                    {/* Available Bookings */}
                    <Box className="booking-section" sx={{ mb: 4 }}>
                        <Typography variant="h5" gutterBottom>Available Dates</Typography>
                        {availableBookings.length > 0 ? (
                            <List>
                                {availableBookings.map(renderBookingRow)}
                            </List>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No available bookings.
                            </Typography>
                        )}
                    </Box>

                    {/* Bookings With Interest */}
                    <Box className="booking-section" sx={{ mb: 4 }}>
                        <Typography variant="h5" gutterBottom>Dates With Interest</Typography>
                        {interestedBookings.length > 0 ? (
                            <List>
                                {interestedBookings.map(renderBookingRow)}
                            </List>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No bookings with interest.
                            </Typography>
                        )}
                    </Box>

                    {/* Booked Bookings */}
                    <Box className="booking-section" sx={{ mb: 4 }}>
                        <Typography variant="h5" gutterBottom>Booked Dates</Typography>
                        {bookedBookings.length > 0 ? (
                            <List>
                                {bookedBookings.map(renderBookingRow)}
                            </List>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No booked dates.
                            </Typography>
                        )}
                    </Box>
                </>
            ) : (
                // Expired Bookings Content
                <>
                    {/* Expired Available Bookings */}
                    <Box className="booking-section" sx={{ mb: 4 }}>
                        <Typography variant="h5" gutterBottom>Expired Available Dates</Typography>
                        {expiredAvailableBookings.length > 0 ? (
                            <List>
                                {expiredAvailableBookings.map(renderBookingRow)}
                            </List>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No expired available bookings.
                            </Typography>
                        )}
                    </Box>

                    {/* Expired Interested Bookings */}
                    <Box className="booking-section" sx={{ mb: 4 }}>
                        <Typography variant="h5" gutterBottom>Expired Dates With Interest</Typography>
                        {expiredInterestedBookings.length > 0 ? (
                            <List>
                                {expiredInterestedBookings.map(renderBookingRow)}
                            </List>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No expired bookings with interest.
                            </Typography>
                        )}
                    </Box>

                    {/* Expired Booked Bookings */}
                    <Box className="booking-section" sx={{ mb: 4 }}>
                        <Typography variant="h5" gutterBottom>Expired Booked Dates</Typography>
                        {expiredBookedBookings.length > 0 ? (
                            <List>
                                {expiredBookedBookings.map(renderBookingRow)}
                            </List>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No expired booked dates.
                            </Typography>
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
  };
  
  export default BookingsList;