import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Avatar,
  styled,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Edit, LocationOn, MusicNote, Group, Event, Stars, Person } from '@mui/icons-material';
import { useUser } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import AvailableDatesPicker from './AvailableDatesPicker';
import SocialMediaSection from './SocialMediaInput';

// Theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#EF6191',
    },
    background: {
      default: '#f5f5f7',
    },
  },
});

// Styled Components
const ProfileContainer = styled(Box)(({ theme }) => ({
  position: 'relative',  // Keep this
  backgroundColor: '#f8f8f8',
  minHeight: '100vh',
  padding: theme.spacing(4),
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-50%',
    left: '-50%',
    width: '200%',
    height: '200%',
    background: 'radial-gradient(circle at center, rgba(239, 97, 145, 0.05), transparent 70%)',
    animation: 'rotateBG 30s linear infinite',
    zIndex: -1,  // Add negative z-index to ensure it stays below content
    pointerEvents: 'none',  // Add this to prevent it from blocking clicks
  },
}));

const HeaderCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(4),
  background: 'linear-gradient(90deg, #EF6191 0%, #2962ff 100%)',
  color: '#fff',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
  background: '#fff',
  marginBottom: theme.spacing(4),
}));


const ImageSlider = styled(Box)(({ theme }) => ({
  '& .slick-slide img': {
    width: '100%',
    height: '400px',
    objectFit: 'cover',
    borderRadius: theme.spacing(2),
  },
  '& .slick-arrow': {
    zIndex: 2,
    background: 'rgba(255, 255, 255, 0.9)',
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    '&:before': {
      color: '#EF6191',
    },
  },
}));


const InfoSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: theme.spacing(3),
  padding: theme.spacing(4),
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.03)',
  display: 'flex',
  gap: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: '#EF6191',
  fontSize: '1.5rem',
  fontWeight: 500,
  marginBottom: theme.spacing(3),
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  color: '#333',
  marginBottom: theme.spacing(3),
}));

const InfoChip = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'rgba(239, 97, 145, 0.08)',
  borderRadius: '12px',
  padding: '12px 16px',
  marginBottom: '12px',
  transition: 'all 0.2s ease',
  cursor: 'default',
  '&:hover': {
    backgroundColor: 'rgba(239, 97, 145, 0.12)',
    transform: 'translateY(-1px)',
  },
  '& .icon': {
    color: '#EF6191',
    marginRight: '12px',
    fontSize: '20px',
  },
  '& .label': {
    color: '#EF6191',
    fontWeight: 500,
    marginRight: '8px',
  },
  '& .value': {
    color: '#333',
  }
}));

const AboutContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  paddingRight: theme.spacing(4),
}));

const AmenitiesContainer = styled(Box)(({ theme }) => ({
  width: '300px',
  borderLeft: '1px solid rgba(0, 0, 0, 0.08)',
  paddingLeft: theme.spacing(4),
}));

const parseStageTypes = (stageTypes) => {
  if (!stageTypes) return 'Not specified';
  
  try {
    const parsed = JSON.parse(stageTypes);
  
    return parsed.join(', ').replace(/['"]/g, '');
  } catch {
    return stageTypes
      .replace(/[\[\]"]/g, '') // Remove brackets and quotes
      .split(',')              // Split into array
      .join(', ');             // Join with proper spacing
  }
};

// Now let's implement the enhanced About section
const AboutSection = ({ performer, setPerformer }) => (
  <InfoSection>
    <AboutContainer>
      <SectionTitle>About</SectionTitle>
      <InfoValue>{performer.bio || '43'}</InfoValue>
      <InfoValue>{performer.location}</InfoValue>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <InfoChip>
          <Group className="icon" />
          <Typography component="span" className="label">Capacity:</Typography>
          <Typography component="span" className="value">
            {performer.capacity || '200'}
          </Typography>
        </InfoChip>

        <InfoChip>
          <Event className="icon" />
          <Typography component="span" className="label">Setting:</Typography>
          <Typography component="span" className="value">
            {performer.setting?.replace(/[\[\]"]/g, '') || 'Bar, Nightclub'}
          </Typography>
        </InfoChip>

        <InfoChip>
        <Typography component="span" className="label">Preferred Contact:</Typography>
        <Typography component="span" className="value">
          {(performer.preferred_contact_method || 'Not specified').charAt(0).toUpperCase() + (performer.preferred_contact_method || 'Not specified').slice(1)}
        </Typography>
      </InfoChip>
      {performer.phone_number && (
        <InfoChip>
          <Typography component="span" className="label">Phone:</Typography>
          <Typography component="span" className="value">
            {performer.phone_number}
          </Typography>
        </InfoChip>
      )}

        <InfoChip sx={{ 
          flexDirection: 'column', 
          alignItems: 'flex-start',
          '& .icon-row': {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '8px',
            width: '100%',
          }
        }}>
          <Event className="icon" />
            <Typography component="span" className="label">Stage Types:</Typography>
            <Typography component="span" className="value">
              {parseStageTypes(performer.stageTypes)}
            </Typography>
        </InfoChip>
      </Box>
    </AboutContainer>

    <AmenitiesContainer>
      <SectionTitle>Amenities & Additional Info</SectionTitle>
      <InfoValue>{performer.amenities || '34'}</InfoValue>
      <SocialMediaSection 
        performer={performer} 
        onUpdateSocialMedia={(updatedData) => {
          setPerformer(updatedData);
          // You'll need to make an API call here to update the backend
          axios.post(`https://api.live-and-local.com/update-profile`, 
            updatedData,
            { withCredentials: true }
          );
        }} 
      />
    </AmenitiesContainer>
  </InfoSection>
);

const parseArrayString = (str) => {
  if (!str) return 'Not specified';
  try {
    const parsed = JSON.parse(str);
    return parsed.join(', ').replace(/['"]/g, '');
  } catch {
    return str.replace(/[\[\]"]/g, '').split(',').join(', ');
  }
};

const PerformerAboutSection = ({ performer, setPerformer  }) => (
  <InfoSection>
    <AboutContainer>
      <SectionTitle>About Performer</SectionTitle>
      <InfoValue>{performer.bio || 'No bio provided'}</InfoValue>
      <InfoValue>{performer.location || 'Location not specified'}</InfoValue>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <InfoChip>
          <MusicNote className="icon" />
          <Typography component="span" className="label">Genre:</Typography>
          <Typography component="span" className="value">
            {performer.genre || 'Not specified'}
          </Typography>
        </InfoChip>

        <InfoChip>
          <Person className="icon" />
          <Typography component="span" className="label">Performance Type:</Typography>
          <Typography component="span" className="value">
            {performer.performance_type || 'Not specified'}
          </Typography>
        </InfoChip>

        <InfoChip>
          <Stars className="icon" />
          <Typography component="span" className="label">Experience Level:</Typography>
          <Typography component="span" className="value">
            {performer.experience_level || 'Not specified'}
          </Typography>
        </InfoChip>

        <InfoChip>
          <Event className="icon" />
          <Typography component="span" className="label">Preferred Venues:</Typography>
          <Typography component="span" className="value">
            {parseArrayString(performer.preferred_venues)}
          </Typography>
        </InfoChip>
      </Box>
    </AboutContainer>

    <AmenitiesContainer>
      <SectionTitle>Contact Information</SectionTitle>
      <InfoChip>
        <Typography component="span" className="label">Preferred Contact:</Typography>
        <Typography component="span" className="value">
          {(performer.preferred_contact_method || 'Not specified').charAt(0).toUpperCase() + (performer.preferred_contact_method || 'Not specified').slice(1)}
        </Typography>
      </InfoChip>
      {performer.phone_number && (
        <InfoChip>
          <Typography component="span" className="label">Phone:</Typography>
          <Typography component="span" className="value">
            {performer.phone_number}
          </Typography>
        </InfoChip>
      )}
      <SocialMediaSection 
        performer={performer} 
        onUpdateSocialMedia={(updatedData) => {
          setPerformer(updatedData);
          // You'll need to make an API call here to update the backend
          axios.post(`https://api.live-and-local.com/update-profile`, 
            updatedData,
            { withCredentials: true }
          );
        }} 
      />
    </AmenitiesContainer>
  </InfoSection>
);

const AboutSectionDiffer = ({ performer, setPerformer }) => {
  if (!performer) return null;
  
  return performer.type === 'performer' ? (
    <PerformerAboutSection performer={performer} setPerformer={setPerformer} />
  ) : (
    <AboutSection performer={performer} setPerformer={setPerformer} />
  );
};


function UserProfile() {
  const { user } = useUser();
  const navigate = useNavigate();
  const [performer, setPerformer] = useState({
    name: '',
    bio: '',
    availableDates: [],
    bookedDates: [],
    interestedDates: [],
    additional_images: [],
    image_url: '',
    type: '',
    setting: '',
    capacity: '',
    stageTypes: '',
    average_attendance: '',
    amenities: '',
    instagram: '',
    facebook: '',
    spotify: '',
    profileId: user?.user_id || ''
  });

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`https://api.live-and-local.com/profile/${user.user_id}`, { 
          withCredentials: true 
        });
        console.log('TOO MUCH SAUCE: ',response.data);
        setPerformer(prev => ({ ...prev, ...response.data, profileId: user.user_id }));
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    if (user?.user_id) {
      fetchProfile();
    }
  }, [user]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const handleSocialMediaUpdate = async (updatedData) => {
    try {
      await axios.post(
        `https://api.live-and-local.com/update-profile`, 
        updatedData,
        { withCredentials: true }
      );
      setPerformer(updatedData);
    } catch (error) {
      console.error('Error updating social media:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ProfileContainer>
        <Container maxWidth="lg">
          {/* Header */}
          <HeaderCard>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Avatar
                  src={performer.image_url}
                  alt={performer.name}
                  sx={{ width: 100, height: 100, border: '4px solid white' }}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="h3" sx={{ fontWeight: 500, mb: 1 }}>
                  {performer.name}
                </Typography>
                <Typography variant="h6" sx={{ opacity: 0.9 }}>
                  {performer.type}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<Edit />}
                  onClick={() => navigate(`/edit-profile/${user.user_id}`)}
                  sx={{
                    backgroundColor: 'white',
                    color: '#EF6191',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    },
                  }}
                >
                  EDIT PROFILE
                </Button>
              </Grid>
            </Grid>
          </HeaderCard>
          <AboutSectionDiffer 
            performer={performer} 
            setPerformer={handleSocialMediaUpdate} 
          />
          {/* Gallery and Availability Section */}
          <StyledCard>
            <CardContent>
              <Grid container spacing={4}>
                {performer.type === 'vendor' ? (
                  // Venue Gallery with Slider
                  <Grid item xs={12} md={8}>
                    <Typography variant="h5" sx={{ color: '#EF6191', mb: 3 }}>
                      Gallery
                    </Typography>
                    <ImageSlider>
                      <Slider {...settings}>
                        <div>
                          <img src={performer.image_url} alt={performer.name} />
                        </div>
                        {performer.additional_images?.map((imageUrl, index) => (
                          <div key={index}>
                            <img src={imageUrl} alt={`${performer.name} ${index + 1}`} />
                          </div>
                        ))}
                      </Slider>
                    </ImageSlider>
                  </Grid>
                ) : (
                  // Artist Single Image
                  <Grid item xs={12} md={8}>
                    <Typography variant="h5" sx={{ color: '#EF6191', mb: 3 }}>
                      Profile Image
                    </Typography>
                    <Box sx={{ 
                      width: '100%',
                      height: '400px',
                      borderRadius: 2,
                      overflow: 'hidden'
                    }}>
                      <img 
                        src={performer.image_url} 
                        alt={performer.name}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  </Grid>
                )}

                {/* Availability section remains the same */}
                <Grid item xs={12} md={4}>
                  <Typography variant="h5" sx={{ color: '#EF6191', mb: 3 }}>
                    Availability
                  </Typography>
                  <AvailableDatesPicker
                    availableDates={performer.availableDates}
                    bookedDates={performer.bookedDates}
                    interestedDates={performer.interestedDates}
                    setAvailableDates={(dates) => setPerformer({...performer, availableDates: dates})}
                    profileId={performer.profileId}
                    isEditable={true}
                    userType={performer.type}
                    isViewProfile={false}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Container>
      </ProfileContainer>
    </ThemeProvider>
  );
}

export default UserProfile;