import React, { useState, useEffect } from 'react';
import { 
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  Avatar,
  Fade,
  Chip,
  Tabs,
  Tab
} from '@mui/material';
import {
  Clock,
  MapPin,
  Music,
  Users,
  Building,
  AlertCircle,
  AlertTriangle
} from 'lucide-react';

import axios from 'axios';
import ArtistBookingBidPopup from './ArtistBookingBidPopup';

const ArtistBookingLastMinute = () => {
    const [bookings, setBookings] = useState({ last_minute: [], cancelled: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [showContractPopup, setShowContractPopup] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const fetchLastMinuteBookings = async () => {
      try {
        const response = await axios.get('https://api.live-and-local.com/LastMinuteBookings', {
          withCredentials: true
        });
        setBookings(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching last minute bookings:', err);
        setError('Failed to load last minute bookings');
        setLoading(false);
      }
    };

    fetchLastMinuteBookings();
    
    // Refresh data every minute to keep hours remaining accurate
    const interval = setInterval(fetchLastMinuteBookings, 60000);
    return () => clearInterval(interval);
  }, []);

  const handleBidClick = (booking) => {
    setSelectedBooking(booking);
    setShowContractPopup(true);
  };

  const LastMinuteCard = ({ booking }) => (
    <Fade in timeout={500}>
      <Paper
        elevation={0}
        sx={{
          mb: 3,
          borderRadius: 2,
          border: '1px solid rgba(0, 0, 0, 0.08)',
          overflow: 'hidden',
          transition: 'all 0.2s ease',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 12px 24px -10px rgba(146, 74, 174, 0.2)',
            borderColor: 'rgba(146, 74, 174, 0.3)',
          }
        }}
      >
        <Box sx={{ p: 3, position: 'relative' }}>
        <Box sx={{ 
          position: 'absolute', 
          top: 16, 
          right: 16,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          gap: 1
        }}>
          {booking.is_cancelled_booking ? (
            <>
              <Chip
                icon={<AlertTriangle size={16} />}
                label="Cancelled Booking - Priority Date"
                sx={{
                  bgcolor: 'rgba(255, 152, 0, 0.1)',
                  color: 'rgb(255, 152, 0)',
                  '& .MuiChip-icon': {
                    color: 'rgb(255, 152, 0)'
                  }
                }}
              />
              <Chip
                icon={<Clock size={16} />}
                label={`${booking.hours_remaining} hours remaining`}
                size="small"
                sx={{
                  bgcolor: 'rgba(255, 152, 0, 0.05)',
                  color: 'rgb(255, 152, 0)',
                  '& .MuiChip-icon': {
                    color: 'rgb(255, 152, 0)'
                  }
                }}
              />
            </>
          ) : (
            <Chip
              icon={<AlertCircle size={16} />}
              label={`${booking.hours_remaining} hours remaining`}
              sx={{
                bgcolor: 'rgba(211, 47, 47, 0.1)',
                color: 'rgb(211, 47, 47)',
                '& .MuiChip-icon': {
                  color: 'rgb(211, 47, 47)'
                }
              }}
            />
          )}
        </Box>

          
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <Avatar
                src={booking.artist_url}
                sx={{ 
                  width: 80, 
                  height: 80,
                  border: '3px solid rgba(146, 74, 174, 0.1)'
                }}
              />
            </Grid>
            <Grid item xs>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  color: '#EF6191',
                  mb: 1
                }}
              >
                {booking.venue_name}
              </Typography>
              
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Clock size={20} className="text-purple-500 mr-2" />
                    <Typography variant="body2" color="text.secondary">
                      {booking.start_time} - {booking.end_time}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <MapPin size={20} className="text-purple-500 mr-2" />
                    <Typography variant="body2" color="text.secondary">
                      {booking.location}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Music size={20} className="text-purple-500 mr-2" />
                    <Typography variant="body2" color="text.secondary">
                      {booking.genre}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Users size={20} className="text-purple-500 mr-2" />
                    <Typography variant="body2" color="text.secondary">
                      Capacity: {booking.capacity}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Building size={20} className="text-purple-500 mr-2" />
                    <Typography variant="body2" color="text.secondary">
                      {booking.venue_type}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              {booking.details && (
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ mt: 2 }}
                >
                  {booking.details}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 3,
            pt: 3,
            borderTop: '1px solid rgba(0, 0, 0, 0.06)'
          }}>
            <Typography variant="h6" sx={{ color: '#EF6191', fontWeight: 500 }}>
              ${booking.min_cost}
            </Typography>
            <Button
              variant="contained"
              onClick={() => handleBidClick(booking)}
              sx={{
                bgcolor: '#EF6191',
                '&:hover': {
                  bgcolor: '#7a3b91'
                }
              }}
            >
              View Details
            </Button>
          </Box>
        </Box>
      </Paper>
    </Fade>
  );

  return (
    <Box sx={{ 
      maxWidth: 1200, 
      mx: 'auto', 
      p: { xs: 2, sm: 3, md: 4 },
      bgcolor: '#ffffff'
    }}>
      <Box sx={{ mb: 6, maxWidth: 720 }}>
        <Typography 
          variant="h3" 
          sx={{ 
            mb: 2, 
            fontWeight: 600,
            color: 'text.primary',
            letterSpacing: '-0.02em'
          }}
        >
          Priority Events
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ 
            color: 'text.secondary',
            lineHeight: 1.6
          }}
        >
          Browse urgent booking opportunities and priority dates from cancellations.
        </Typography>
      </Box>

      <Tabs 
        value={activeTab} 
        onChange={(e, newValue) => setActiveTab(newValue)}
        sx={{ mb: 4 }}
      >
        <Tab 
          label={`Last Minute (${bookings.last_minute.length})`}
          sx={{ color: '#EF6191' }}
        />
        <Tab 
          label={`Cancelled Dates (${bookings.cancelled.length})`}
          sx={{ color: '#EF6191' }}
        />
      </Tabs>

      {loading ? (
        <Typography textAlign="center" color="text.secondary">
          Loading bookings...
        </Typography>
      ) : error ? (
        <Typography textAlign="center" color="error">
          {error}
        </Typography>
      ) : (
        <Box>
          {activeTab === 0 ? (
            bookings.last_minute.length === 0 ? (
              <Typography textAlign="center" color="text.secondary">
                No last minute bookings available at the moment.
              </Typography>
            ) : (
              bookings.last_minute.map((booking) => (
                <LastMinuteCard key={booking.booking_id} booking={booking} />
              ))
            )
          ) : (
            bookings.cancelled.length === 0 ? (
              <Typography textAlign="center" color="text.secondary">
                No cancelled dates available at the moment.
              </Typography>
            ) : (
              bookings.cancelled.map((booking) => (
                <LastMinuteCard key={booking.booking_id} booking={booking} />
              ))
            )
          )}
        </Box>
      )}

      {showContractPopup && selectedBooking && (
        <ArtistBookingBidPopup
          booking={selectedBooking}
          onClose={() => setShowContractPopup(false)}
        />
      )}
    </Box>
  );
};

export default ArtistBookingLastMinute;