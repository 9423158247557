import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Music, Save } from 'lucide-react';

const SocialMediaSection = ({ performer, onUpdateSocialMedia }) => {
  const [socialLinks, setSocialLinks] = useState({
    instagram: performer?.instagram || '',
    facebook: performer?.facebook || '',
    spotify: performer?.spotify || ''
  });
  
  useEffect(() => {
    setSocialLinks({
      instagram: performer?.instagram || '',
      facebook: performer?.facebook || '',
      spotify: performer?.spotify || ''
    });
  }, [performer]);

  const handleChange = (field, value) => {
    setSocialLinks(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSave = (field) => {
    const updatedPerformer = {
      ...performer,
      [field]: socialLinks[field]
    };
    onUpdateSocialMedia(updatedPerformer);
  };

  const hasChanges = {
    instagram: socialLinks.instagram !== (performer?.instagram || ''),
    facebook: socialLinks.facebook !== (performer?.facebook || ''),
    spotify: socialLinks.spotify !== (performer?.spotify || '')
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" sx={{ 
        color: '#EF6191',
        fontWeight: 500,
        mb: 2 
      }}>
        Social Media Links
      </Typography>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ 
          backgroundColor: 'rgba(239, 97, 145, 0.08)',
          p: 2,
          borderRadius: 2
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1 
          }}>
            <InstagramIcon sx={{ color: '#EF6191' }} />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Instagram username"
              value={socialLinks.instagram}
              onChange={(e) => handleChange('instagram', e.target.value)}
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'white'
                }
              }}
            />
            <Button
              size="small"
              onClick={() => handleSave('instagram')}
              disabled={!hasChanges.instagram}
              sx={{
                minWidth: 'unset',
                px: 2,
                py: 1,
                backgroundColor: 'white',
                color: '#EF6191',
                '&:hover': {
                  backgroundColor: 'rgba(239, 97, 145, 0.08)',
                },
                '&.Mui-disabled': {
                  color: 'rgba(239, 97, 145, 0.26)'
                }
              }}
            >
              <Save size={16} />
            </Button>
          </Box>
        </Box>

        <Box sx={{ 
          backgroundColor: 'rgba(239, 97, 145, 0.08)',
          p: 2,
          borderRadius: 2
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1 
          }}>
            <FacebookIcon sx={{ color: '#EF6191' }} />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Facebook profile URL"
              value={socialLinks.facebook}
              onChange={(e) => handleChange('facebook', e.target.value)}
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'white'
                }
              }}
            />
            <Button
              size="small"
              onClick={() => handleSave('facebook')}
              disabled={!hasChanges.facebook}
              sx={{
                minWidth: 'unset',
                px: 2,
                py: 1,
                backgroundColor: 'white',
                color: '#EF6191',
                '&:hover': {
                  backgroundColor: 'rgba(239, 97, 145, 0.08)',
                },
                '&.Mui-disabled': {
                  color: 'rgba(239, 97, 145, 0.26)'
                }
              }}
            >
              <Save size={16} />
            </Button>
          </Box>
        </Box>

        <Box sx={{ 
          backgroundColor: 'rgba(239, 97, 145, 0.08)',
          p: 2,
          borderRadius: 2
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1 
          }}>
            <Music size={24} style={{ color: '#EF6191' }} />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Spotify artist URL"
              value={socialLinks.spotify}
              onChange={(e) => handleChange('spotify', e.target.value)}
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'white'
                }
              }}
            />
            <Button
              size="small"
              onClick={() => handleSave('spotify')}
              disabled={!hasChanges.spotify}
              sx={{
                minWidth: 'unset',
                px: 2,
                py: 1,
                backgroundColor: 'white',
                color: '#EF6191',
                '&:hover': {
                  backgroundColor: 'rgba(239, 97, 145, 0.08)',
                },
                '&.Mui-disabled': {
                  color: 'rgba(239, 97, 145, 0.26)'
                }
              }}
            >
              <Save size={16} />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SocialMediaSection;