import React, { useState, useEffect, useRef  } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Header.css';
import { X } from 'lucide-react';
import axios from 'axios';
import { useUser } from '../context/UserContext';
import icon from './L&L Logo copy.png';  // Adjust the path according to your directory structure

function Header() {
    const { user, logout } = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const [profileStatus, setProfileStatus] = useState(null);
    const [isProfileLoading, setIsProfileLoading] = useState(true);
    const [profileError, setProfileError] = useState(null);
    const [image, setImage] = useState()
    const [name, setName] = useState()
    const dropdownRef = useRef(null);
    const [msgCount, setMsgCount] = useState()
    const isOnBookingsPage = () => {
        return location.pathname.includes('/Userbooking') || location.pathname.includes('/Userreservations');
    };
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const fetchProfileId = async () => {
          try {
            const response = await axios.get(`https://api.live-and-local.com/get-profile/${user.user_id}`);
            setProfileStatus(response.data.status); // Adjust based on your API response structure
            setIsProfileLoading(false);
            setImage(response.data.Image)
            setName(response.data.name)
            setMsgCount(response.data.unread_messages)
            console.log(response.data)
            console.log(msgCount)
          } catch (error) {
            console.error('Error fetching profile ID:', error);
            setProfileError(error);
            setIsProfileLoading(false);
          }
        };
    
        if (user && user.user_id) {
          fetchProfileId();
        }
      }, [user]);

      useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Reset dropdown state when user changes
    useEffect(() => {
        setIsOpen(false);
    }, [user]);

    const toggleDropdown = () => setIsOpen(!isOpen);

      const UnstoppableLink = ({ to, children, onClick }) => (
        <Link 
            to={to}
            onClick={onClick}
            style={{
                position: 'relative',
                zIndex: 99999,
                pointerEvents: 'auto',
                cursor: 'pointer',
                display: 'inline-block',
                margin: '0 1%',
                textDecoration: 'none',
                color: '#EF6191',
                fontSize: '16px',
                fontWeight: '500',
                padding: '5px 10px',
            }}
        >
            {children}
        </Link>
    );

    return (
        <>
            <header>
                <div className="header-content">
                    <img src={icon} alt="Logo" className="header-icon" />
                    <h1>Live & Local</h1>
                </div>
                <nav>
                    {user ? (
                        <>
                            {/* Conditional Home link based on profileStatus */}
                            {profileStatus === 'vendor' ? (
                                <>
                                    <Link to={`/VenueLandingPage/${user.user_id}`}>Home</Link>
                                    <Link to='/VendorImpBooking/:profile_id'>Bookings</Link>
                                </>
                            ) : (
                                <>
                                    <Link to={`/ArtistLandingPage/${user.user_id}`}>Home</Link>
                                    <Link to='/ArtistImpBooking/:profile_id'>Bookings</Link>
                                </>
                            )}
                            <Link to="/search">Search</Link>
                            <Link to="/messages/:profile_id">Messages ({msgCount})</Link>
                        
                            <div ref={dropdownRef} style={{ position: 'relative', display: 'inline-block' }}>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleDropdown();
                                    }}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                        textDecoration: 'none',
                                        color: 'inherit',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <img
                                        src={image}
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            objectFit: 'cover',
                                            border: '2px solid #ddd',
                                        }}
                                        alt="Profile"
                                    />
                                    <p style={{margin: 0}}>{name}</p>
                                </a>

                                {isOpen && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '50px',
                                            right: 0,
                                            backgroundColor: 'white',
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                                            borderRadius: '8px',
                                            zIndex: 1000,
                                            padding: '10px',
                                            width: '150px',
                                        }}
                                    >
                                        <button
                                            onClick={() => setIsOpen(false)}
                                            style={{
                                                position: 'absolute',
                                                top: '5px',
                                                right: '5px',
                                                background: 'none',
                                                border: 'none',
                                                cursor: 'pointer',
                                                padding: '2px',
                                            }}
                                        >
                                            <X size={16} color="#666" />
                                        </button>
                                        <div style={{ marginTop: '1%' }}>
                                            <Link
                                                to="/user/profile"
                                                style={{
                                                    display: 'block',
                                                    padding: '10px 15px',
                                                    textDecoration: 'none',
                                                    color: '#EF6191',
                                                    fontWeight: 'bold',
                                                    borderRadius: '4px',
                                                    transition: 'background-color 0.3s ease',
                                                }}
                                                onMouseEnter={(e) => (e.target.style.backgroundColor = '#f0f0f0')}
                                                onMouseLeave={(e) => (e.target.style.backgroundColor = 'white')}
                                            >
                                                View Profile
                                            </Link>
                                            <Link
                                                to="/"
                                                onClick={() => {
                                                    logout();
                                                    setIsOpen(false);
                                                }}
                                                style={{
                                                    display: 'block',
                                                    padding: '10px 15px',
                                                    textDecoration: 'none',
                                                    color: '#EF6191',
                                                    fontWeight: 'bold',
                                                    borderRadius: '4px',
                                                    transition: 'background-color 0.3s ease',
                                                }}
                                                onMouseEnter={(e) => (e.target.style.backgroundColor = '#f0f0f0')}
                                                onMouseLeave={(e) => (e.target.style.backgroundColor = 'white')}
                                            >
                                                Log Out
                                            </Link>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </>
                    ) : (
                        <>
                            <UnstoppableLink to="/">Home</UnstoppableLink>
                            <UnstoppableLink to="/search">Search</UnstoppableLink>
                            <UnstoppableLink to="/login">Login</UnstoppableLink>
                            <UnstoppableLink to="/register">Register</UnstoppableLink>
                        </>
                    )}
                </nav>
            </header>
            {isOnBookingsPage() ? (
                <div className="sub-header">
                    <nav>
                        <Link to='/Userbooking/:profile_id'>My Bookings</Link>
                        <Link to='/Userreservations/:profile_id'>My Reservations</Link>
                    </nav>
                </div>
            ) : (
                <div className="sub-header">
                    <nav>
                        <Link to='/performers'>Performers</Link>
                        <Link to='/vendors'>Vendors</Link>
                    </nav>
                </div>
            )}
            <p></p>
        </>
    );
}

export default Header;