import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  CircularProgress
} from '@mui/material';
import { AlertTriangle } from 'lucide-react';

const CancelBookingDialog = ({ open, onClose, onConfirm, isLoading, bookingDetails }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ 
        bgcolor: 'error.main', 
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}>
        <AlertTriangle />
        Cancel Booking Confirmation
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Are you sure you want to cancel this booking?
        </Typography>
        
        <Box sx={{ 
          bgcolor: 'error.lighter', 
          p: 2, 
          borderRadius: 1,
          mb: 2
        }}>
          <Typography variant="body2" color="error.dark">
            This action cannot be undone. Cancelling this booking will:
          </Typography>
          <ul style={{ marginTop: 8, color: '#d32f2f' }}>
            <li>Remove this date from your active bookings</li>
            <li>Mark the date as cancelled in your calendar</li>
            <li>Notify any artists who have shown interest</li>
          </ul>
        </Box>

        {bookingDetails && (
          <Box sx={{ 
            bgcolor: 'grey.50', 
            p: 2, 
            borderRadius: 1 
          }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Booking Details:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Date: {bookingDetails.date}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Time: {bookingDetails.start_time} - {bookingDetails.end_time}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 2, pt: 0 }}>
        <Button 
          onClick={onClose}
          disabled={isLoading}
        >
          Keep Booking
        </Button>
        <Button 
          variant="contained" 
          color="error"
          onClick={onConfirm}
          disabled={isLoading}
          startIcon={isLoading ? <CircularProgress size={20} /> : null}
        >
          {isLoading ? 'Cancelling...' : 'Cancel Booking'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelBookingDialog;