import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import './viewProfile.css';
import ArtistBookingBidPopup from './ArtistBookingBidPopup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {Tooltip, Box, 
    Typography, 
    Button, 
    DialogContent,
    DialogActions,
    Paper,
    Rating,
    CircularProgress,
    IconButton
} from "@mui/material";
import { Close as CloseIcon } from '@mui/icons-material';
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';


const localizer = momentLocalizer(moment);

const eventStyleGetter = (event, start, end, isSelected) => {
    let style = {
        backgroundColor: '#4caf50', // Default color for available dates
        borderRadius: '0px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block'
    };

    if (event.isBooked) {
        style.backgroundColor = '#e82121'; // Red for booked dates
    }

    return { style };
};

function ViewProfile() {
    const { profile_id } = useParams();
    const [profile, setProfile] = useState();
    const [selectedData, setSelectedData] = useState(null);
    const [showContractPopup, setShowContractPopup] = useState(false);
    const [data, setData] = useState([{}]);
    const [artistName, setArtistName] = useState();
    const [showBookedArtist, setBookedArtist] = useState(false);
    const [events, setEvents] = useState([]);
    const [reviewScore, setReviewScore] = useState(0);
    const [reviewCount, setReviewCount] = useState(0);
    const [showReviewsModal, setShowReviewsModal] = useState(false);
    const [profileReviews, setProfileReviews] = useState([]);
    const [loadingReviews, setLoadingReviews] = useState(false);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
    };

    const renderImages = () => {
        if (profile.type === 'vendor') {
            if (profile.images && profile.images.length > 0) {
                return (
                    <div
                        className="profile-carousel"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%'
                        }}
                    >
                        <Slider {...settings}>
                            {profile.images.map((imageUrl, index) => (
                                <div key={index} style={{display: 'flex', justifyContent: 'center'}}>
                                    <img
                                        src={imageUrl}
                                        alt={`${profile.name} ${index + 1}`}
                                        style={{maxWidth: '100%', height: 'auto'}}
                                    />
                                </div>
                            ))}
                            <div>
                                <iframe
                                    title="Map"
                                    src={`https://www.google.com/maps?q=${encodeURIComponent(profile.location || 'New York City, NY')}&output=embed`}
                                    style={{
                                        width: '100%',
                                        height: "350px",
                                        border: 'none',
                                        borderRadius: '10px'
                                    }}
                                    allowFullScreen
                                    loading="lazy"
                                ></iframe>
                            </div>
                        </Slider>
                    </div>
                );
            }
        }
        return null;
    };

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showMessagePopup, setShowMessagePopup] = useState(false);
    const [messageContent, setMessageContent] = useState("");

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(
                    `https://api.live-and-local.com/view_profile/${profile_id}`);

                const data = response.data;
                setProfile(data);

                try {
                    const reviewsResponse = await axios.get(
                        `https://api.live-and-local.com/api/reviews/score/${profile_id}`,
                        { withCredentials: true }
                    );
                    setReviewScore(reviewsResponse.data.averageRating || 0);
                    setReviewCount(reviewsResponse.data.totalReviews || 0);
                } catch (reviewErr) {
                    console.error("Failed to fetch review score:", reviewErr);
                }

                console.log(data);
                let bookings_response;
                console.log(profile_id);
                if (data.type === 'vendor') {
                    bookings_response = (await axios.post(`
                https://api.live-and-local.com/getArtistBookingsFromViewProfile/${profile_id}`, {})).data;
                }
                else {
                    bookings_response = (await axios.post(`
                https://api.live-and-local.com/getArtistBookingsByID/${profile_id}`, {})).data;
                }
                setData(bookings_response);
                console.log(bookings_response);
                const mappedEvents = bookings_response.map(booking => {
                    const date = booking.date.split(' ').slice(0, 4).join(' ');
                    const start = moment(`${date} ${booking.start_time}`, 'ddd, DD MMM YYYY HH:mm:ss').toDate();
                    const end = moment(`${date} ${booking.end_time}`, 'ddd, DD MMM YYYY HH:mm:ss').toDate();
                    if (data.type === 'vendor') {
                        let title = "Available";
                        if (booking.status === 'booked') {
                            title = "Booked"
                        }
                        return {
                            id: booking.booking_id,
                            title: title,
                            start: start,
                            end: end,
                            isBooked: booking.status.toLowerCase() === 'booked',
                            venue: booking.venue_name || 'Main Stage',
                            description: booking.details?.description || booking.details || '',
                            capacity: parseInt(booking.capacity, 10) || 500,
                            genres: booking.genre ? booking.genre.split(', ') : [],
                            techSpecs: booking.details?.techSpecs || {}
                        };
                    }
                    else if (booking.status === 'Booked') {
                        return {
                            title: `Booked at ${booking.name}`,
                            start: start,
                            end: end,
                            isBooked: booking.status.toLowerCase() === 'booked',
                            venue: booking.venue_name || 'Main Stage',
                            description: booking.artist_notes,
                            artistName: booking.artistName,
                            location: booking.location
                        };
                    }
                });

                setEvents(mappedEvents);
                console.log(events);
                setLoading(false);
            } catch (err) {
                console.error("Failed to fetch profile data:", err);
                setError('Failed to fetch profile data');
                setLoading(false);
            }
        };

        fetchProfile();
    }, [profile_id]);


    const SocialMediaIcons = ({ profile }) => {
        return (
          <p style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            {profile.instagram && (
              <a href={`https://instagram.com/${profile.instagram}`} target="_blank" className="icons">
                <InstagramIcon style={{ fontSize: '2em' }} />
              </a>
            )}
            
            {profile.facebook && (
              <a href={`https://www.facebook.com/${profile.facebook}`} target="_blank" className="icons">
                <FacebookIcon style={{ fontSize: '2em' }} />
              </a>
            )}
            
            {profile.spotify && (
              <a href={`https://open.spotify.com/user/${profile.spotify}`} target="_blank" className="icons">
                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M17.9 10.9C14.7 9 9.35 8.8 6.3 9.75c-.5.15-1-.15-1.15-.6c-.15-.5.15-1 .6-1.15c3.55-1.05 9.4-.85 13.1 1.35c.45.25.6.85.35 1.3c-.25.35-.85.5-1.3.25m-.1 2.8c-.25.35-.7.5-1.05.25c-2.7-1.65-6.8-2.15-9.95-1.15c-.4.1-.85-.1-.95-.5s.1-.85.5-.95c3.65-1.1 8.15-.55 11.25 1.35c.3.15.45.65.2 1m-1.2 2.75c-.2.3-.55.4-.85.2c-2.35-1.45-5.3-1.75-8.8-.95c-.35.1-.65-.15-.75-.45c-.1-.35.15-.65.45-.75c3.8-.85 7.1-.5 9.7 1.1c.35.15.4.55.25.85M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2"
                  />
                </svg>
              </a>
            )}
          </p>
        );
      };
      
    const handleDateClick = (date) => {
        if (date.title === 'Booked') {
            axios.get(`https://api.live-and-local.com/getArtistPlaying/${date.id}`)
                .then(response => {
                    const artistName = response.data;
                    setArtistName(artistName[0].artist_name);
                    setBookedArtist(true);
                    setSelectedData(date)
                })
                .catch(error => {
                    console.error('Error fetching artist name:', error);
                });
        } else if (date.title === "Available") {
            const bookingId = date.id;
            let matchedData = data.find(item => item.booking_id === bookingId);
            matchedData.start_time = moment(date.start).format('h:mm A')
            matchedData.end_time = moment(date.end).format('h:mm A')
            matchedData.date = moment(date.end).format('MM/DD/YYYY')
            setSelectedData(matchedData);
            setShowContractPopup(true);
        }

    };

    const handleCloseContractPopup = () => {
        setShowContractPopup(false);
    };

    const ArtistNameHandleClose = () => {
        setArtistName(null);
        setBookedArtist(false);
    }

    const handleViewReviews = async () => {
        if (reviewCount === 0) return;
        
        setLoadingReviews(true);
        setShowReviewsModal(true);
        
        try {
            const response = await axios.get(
                `https://api.live-and-local.com/api/reviews/profile/${profile_id}`,
                { withCredentials: true }
            );
            console.log('CONOR',response.data.data);
            setProfileReviews(response.data.data);
        } catch (err) {
            console.error('Failed to fetch reviews:', err);
        } finally {
            setLoadingReviews(false);
        }
    };
    
    // Add this component for rendering each review
    const ReviewCard = ({ review }) => {
        const dateObject = new Date(review.createdAt);
        
        return (
            <Paper 
                elevation={0}
                sx={{
                    mb: 2,
                    borderRadius: 2,
                    border: '1px solid rgba(0, 0, 0, 0.08)',
                    position: 'relative',
                    p: 3,
                    '&:hover': {
                        boxShadow: '0 12px 24px -10px rgba(146, 74, 174, 0.2)'
                    }
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {review.reviewerName}
                    </Typography>
                    <Rating value={review.rating} readOnly />
                </Box>
                <Typography variant="body2" color="text.secondary">
                    {review.comment}
                </Typography>
                <Typography variant="caption" color="text.secondary" sx={{ mt: 2, display: 'block' }}>
                    {dateObject.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })}
                </Typography>
            </Paper>
        );
    };
    

    const EventComponent = ({ event }) => (
        <Tooltip
            title={
                <div>
                    <strong>{event.title}</strong>
                    {profile.type === 'vendor' ? (
                        <>
                            <p>Venue: {event.venue || 'N/A'}</p>
                            <p>Time: {moment(event.start).format('h:mm A')} - {moment(event.end).format('h:mm A')}</p>
                            <p>Capacity: {event.capacity || 'N/A'}</p>
                            <p>Genres: {event.genres && event.genres.length > 0 ? event.genres.join(', ') : 'N/A'}</p>
                            <p>Description: {event.description || 'N/A'}</p>
                        </>
                    ) : (
                        <>
                            <p>Artist: {event.artistName || 'N/A'}</p>
                            <p>Time: {moment(event.start).format('h:mm A')} - {moment(event.end).format('h:mm A')}</p>
                            <p>Location: {event.location}</p>
                            <p>Artist Notes: {event.description}</p>
                        </>
                    )}

                </div>
            }
            arrow
        >
            <div>{event.title}</div>
        </Tooltip>

    );

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;


    return (
        <div>
            <div className="user-profile">
                <h2>{profile.name || 'Default Name'}</h2>
                <>
                    {profile.type === 'vendor' ? (
                        renderImages()
                    ) : (
                        <img
                            src={profile.images[0] || '/default-image.png'}
                            alt={profile.name}
                            style={{
                                maxWidth: '300px',
                                borderRadius: '10px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
                            }}
                        />
                    )}
                </>
                <p style={{alignItems: 'left'}}><strong>Bio:</strong> {profile.bio || 'No bio available'}</p>
                {profile.type === 'vendor' && (
                    <>
                        <table>
                            <tbody>
                            <tr>
                                <td><p><strong>Location:</strong><br/> {profile.location || 'No location available'}</p>
                                </td>
                                <td><p><strong>Setting:</strong><br/> {profile.setting || 'No setting specified'}</p>
                                </td>
                                <td><p><strong>Stage
                                    Types: </strong><br/> {profile.stageTypes || 'No stage type specified'}</p></td>
                                <td><p><strong>Average
                                    Attendance:</strong> {profile.averageAttendance || 'No average attendance available'}
                                </p></td>
                            </tr>
                            </tbody>
                        </table>
                        <p><strong>Other Notes:</strong> {profile.amenities || 'No amamenties specified'}</p>
                        <td>
                            <p>
                                <strong>Reviews:</strong><br/>
                                {reviewCount > 0 ? (
                                    <Button
                                        onClick={handleViewReviews}
                                        sx={{
                                            color: '#924AAE',
                                            textDecoration: 'underline',
                                            padding: 0,
                                            '&:hover': {
                                                background: 'none',
                                                opacity: 0.8
                                            }
                                        }}
                                    >
                                        {`${reviewScore.toFixed(1)}/5 (${reviewCount} reviews)`}
                                    </Button>
                                ) : (
                                    'No reviews yet'
                                )}
                            </p>
                        </td>

                        <Dialog 
                            open={showReviewsModal} 
                            onClose={() => setShowReviewsModal(false)}
                            fullWidth
                            maxWidth="md"
                            TransitionProps={{
                                timeout: 300
                            }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    borderRadius: '24px',
                                    background: 'linear-gradient(145deg, #fff 0%, #fcfaff 100%)',
                                    backdropFilter: 'blur(20px)',
                                    border: '1px solid rgba(239, 97, 145, 0.1)',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        height: '180px',
                                        background: 'linear-gradient(145deg, rgba(239, 97, 145, 0.08) 0%, rgba(255, 255, 255, 0) 100%)',
                                        zIndex: 0
                                    }
                                }
                            }}
                        >
                            {/* Close Button */}
                            <Box sx={{ 
                                position: 'absolute', 
                                top: 0, 
                                right: 0,
                                m: 2,
                                zIndex: 1
                            }}>
                                <IconButton
                                    onClick={() => setShowReviewsModal(false)}
                                    sx={{
                                        color: '#EF6191',
                                        backgroundColor: 'rgba(239, 97, 145, 0.08)',
                                        backdropFilter: 'blur(8px)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(239, 97, 145, 0.12)',
                                            transform: 'scale(1.05)',
                                        },
                                        transition: 'all 0.2s ease-in-out'
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>

                            {/* Header Content */}
                            <Box sx={{ px: 4, pt: 4, pb: 2, position: 'relative' }}>
                                <Typography variant="h4" sx={{ 
                                    fontWeight: 700,
                                    background: 'linear-gradient(135deg, #EF6191 0%, #B366CC 100%)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    mb: 1
                                }}>
                                    {profile?.name}
                                </Typography>
                                <Typography 
                                    variant="h6" 
                                    sx={{ 
                                        color: 'text.secondary',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1
                                    }}
                                >
                                    {reviewCount > 0 ? (
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            p: 1.5,
                                            borderRadius: '12px',
                                            bgcolor: 'rgba(239, 97, 145, 0.04)',
                                            border: '1px solid rgba(239, 97, 145, 0.06)'
                                        }}>
                                            <Typography 
                                                component="span" 
                                                sx={{ 
                                                    color: '#EF6191',
                                                    fontWeight: 600,
                                                    fontSize: '1.25rem'
                                                }}
                                            >
                                                {reviewScore.toFixed(1)}/5
                                            </Typography>
                                            <Typography 
                                                component="span"
                                                sx={{ 
                                                    color: 'text.secondary',
                                                    fontSize: '0.875rem'
                                                }}
                                            >
                                                from {reviewCount} reviews
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Typography 
                                            sx={{ 
                                                color: 'text.secondary',
                                                fontStyle: 'italic'
                                            }}
                                        >
                                            No reviews yet
                                        </Typography>
                                    )}
                                </Typography>
                            </Box>

                            {/* Reviews Content */}
                            <DialogContent 
                                sx={{ 
                                    px: 4,
                                    pb: 4,
                                    '&::-webkit-scrollbar': {
                                        width: '8px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        background: 'rgba(239, 97, 145, 0.04)',
                                        borderRadius: '4px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: 'rgba(239, 97, 145, 0.2)',
                                        borderRadius: '4px',
                                        '&:hover': {
                                            background: 'rgba(239, 97, 145, 0.3)',
                                        },
                                    },
                                }}
                            >
                                {loadingReviews ? (
                                    <Box sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'center', 
                                        py: 8,
                                        position: 'relative'
                                    }}>
                                        <CircularProgress 
                                            sx={{ 
                                                color: '#EF6191',
                                                '& .MuiCircularProgress-circle': {
                                                    strokeLinecap: 'round',
                                                }
                                            }} 
                                        />
                                    </Box>
                                ) : profileReviews.length > 0 ? (
                                    <Box sx={{ 
                                        py: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 3
                                    }}>
                                        {profileReviews.map((review) => (
                                            <ReviewCard key={review.id} review={review} />
                                        ))}
                                    </Box>
                                ) : (
                                    <Box sx={{ 
                                        py: 8,
                                        textAlign: 'center',
                                        bgcolor: 'rgba(239, 97, 145, 0.02)',
                                        borderRadius: '16px',
                                        border: '1px solid rgba(239, 97, 145, 0.08)'
                                    }}>
                                        <Typography 
                                            color="text.secondary"
                                            sx={{ 
                                                fontStyle: 'italic',
                                                fontSize: '1.1rem'
                                            }}
                                        >
                                            No reviews available
                                        </Typography>
                                    </Box>
                                )}
                            </DialogContent>

                            {/* Footer */}
                            <Box sx={{ 
                                px: 4, 
                                py: 3, 
                                borderTop: '1px solid rgba(239, 97, 145, 0.08)',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <Button 
                                    onClick={() => setShowReviewsModal(false)}
                                    sx={{
                                        borderRadius: '12px',
                                        padding: '12px 24px',
                                        color: '#EF6191',
                                        backgroundColor: 'rgba(239, 97, 145, 0.04)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(239, 97, 145, 0.08)',
                                        },
                                        transition: 'all 0.2s ease-in-out',
                                        textTransform: 'none',
                                        fontSize: '1rem',
                                        fontWeight: 500
                                    }}
                                >
                                    Close
                                </Button>
                            </Box>
                        </Dialog>
                    </>
                )}

                {profile.type === 'performer' && (
                    <>
                        <table>
                            <tbody>
                            <tr>
                                <td><p><strong>Location:</strong><br/> {profile.location
                                    || 'No location available'}</p></td>
                                <td><p><strong>Genre:</strong><br/> {profile.genre || 'No genre specified'}</p></td>
                                <td><p><strong>Type:</strong><br/> {profile.performance_type || 'No type available'}
                                </p></td>
                                <td><p>
                                    <strong>Experience:</strong><br/> {profile.experience_level || 'No Experience available'}
                                </p></td>
                                <td><p><strong>Preferred Venues:</strong><br/> {profile.perferred_venues
                                    || 'No preferred_venues available'}</p></td>
                            </tr>
                            </tbody>
                        </table>
                        <td>
                            <p>
                                <strong>Reviews:</strong><br/>
                                {reviewCount > 0 ? (
                                    <Button
                                        onClick={handleViewReviews}
                                        sx={{
                                            color: '#924AAE',
                                            textDecoration: 'underline',
                                            padding: 0,
                                            '&:hover': {
                                                background: 'none',
                                                opacity: 0.8
                                            }
                                        }}
                                    >
                                        {`${reviewScore.toFixed(1)}/5 (${reviewCount} reviews)`}
                                    </Button>
                                ) : (
                                    'No reviews yet'
                                )}
                            </p>
                        </td>

                        <Dialog 
                            open={showReviewsModal} 
                            onClose={() => setShowReviewsModal(false)}
                            fullWidth
                            maxWidth="md"
                            TransitionProps={{
                                timeout: 300
                            }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    borderRadius: '24px',
                                    background: 'linear-gradient(145deg, #fff 0%, #fcfaff 100%)',
                                    backdropFilter: 'blur(20px)',
                                    border: '1px solid rgba(239, 97, 145, 0.1)',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        height: '180px',
                                        background: 'linear-gradient(145deg, rgba(239, 97, 145, 0.08) 0%, rgba(255, 255, 255, 0) 100%)',
                                        zIndex: 0
                                    }
                                }
                            }}
                        >
                            {/* Close Button */}
                            <Box sx={{ 
                                position: 'absolute', 
                                top: 0, 
                                right: 0,
                                m: 2,
                                zIndex: 1
                            }}>
                                <IconButton
                                    onClick={() => setShowReviewsModal(false)}
                                    sx={{
                                        color: '#EF6191',
                                        backgroundColor: 'rgba(239, 97, 145, 0.08)',
                                        backdropFilter: 'blur(8px)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(239, 97, 145, 0.12)',
                                            transform: 'scale(1.05)',
                                        },
                                        transition: 'all 0.2s ease-in-out'
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>

                            {/* Header Content */}
                            <Box sx={{ px: 4, pt: 4, pb: 2, position: 'relative' }}>
                                <Typography variant="h4" sx={{ 
                                    fontWeight: 700,
                                    background: 'linear-gradient(135deg, #EF6191 0%, #B366CC 100%)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    mb: 1
                                }}>
                                    {profile?.name}
                                </Typography>
                                <Typography 
                                    variant="h6" 
                                    sx={{ 
                                        color: 'text.secondary',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1
                                    }}
                                >
                                    {reviewCount > 0 ? (
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            p: 1.5,
                                            borderRadius: '12px',
                                            bgcolor: 'rgba(239, 97, 145, 0.04)',
                                            border: '1px solid rgba(239, 97, 145, 0.06)'
                                        }}>
                                            <Typography 
                                                component="span" 
                                                sx={{ 
                                                    color: '#EF6191',
                                                    fontWeight: 600,
                                                    fontSize: '1.25rem'
                                                }}
                                            >
                                                {reviewScore.toFixed(1)}/5
                                            </Typography>
                                            <Typography 
                                                component="span"
                                                sx={{ 
                                                    color: 'text.secondary',
                                                    fontSize: '0.875rem'
                                                }}
                                            >
                                                from {reviewCount} reviews
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Typography 
                                            sx={{ 
                                                color: 'text.secondary',
                                                fontStyle: 'italic'
                                            }}
                                        >
                                            No reviews yet
                                        </Typography>
                                    )}
                                </Typography>
                            </Box>

                            {/* Reviews Content */}
                            <DialogContent 
                                sx={{ 
                                    px: 4,
                                    pb: 4,
                                    '&::-webkit-scrollbar': {
                                        width: '8px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        background: 'rgba(239, 97, 145, 0.04)',
                                        borderRadius: '4px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: 'rgba(239, 97, 145, 0.2)',
                                        borderRadius: '4px',
                                        '&:hover': {
                                            background: 'rgba(239, 97, 145, 0.3)',
                                        },
                                    },
                                }}
                            >
                                {loadingReviews ? (
                                    <Box sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'center', 
                                        py: 8,
                                        position: 'relative'
                                    }}>
                                        <CircularProgress 
                                            sx={{ 
                                                color: '#EF6191',
                                                '& .MuiCircularProgress-circle': {
                                                    strokeLinecap: 'round',
                                                }
                                            }} 
                                        />
                                    </Box>
                                ) : profileReviews.length > 0 ? (
                                    <Box sx={{ 
                                        py: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 3
                                    }}>
                                        {profileReviews.map((review) => (
                                            <ReviewCard key={review.id} review={review} />
                                        ))}
                                    </Box>
                                ) : (
                                    <Box sx={{ 
                                        py: 8,
                                        textAlign: 'center',
                                        bgcolor: 'rgba(239, 97, 145, 0.02)',
                                        borderRadius: '16px',
                                        border: '1px solid rgba(239, 97, 145, 0.08)'
                                    }}>
                                        <Typography 
                                            color="text.secondary"
                                            sx={{ 
                                                fontStyle: 'italic',
                                                fontSize: '1.1rem'
                                            }}
                                        >
                                            No reviews available
                                        </Typography>
                                    </Box>
                                )}
                            </DialogContent>

                            {/* Footer */}
                            <Box sx={{ 
                                px: 4, 
                                py: 3, 
                                borderTop: '1px solid rgba(239, 97, 145, 0.08)',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <Button 
                                    onClick={() => setShowReviewsModal(false)}
                                    sx={{
                                        borderRadius: '12px',
                                        padding: '12px 24px',
                                        color: '#EF6191',
                                        backgroundColor: 'rgba(239, 97, 145, 0.04)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(239, 97, 145, 0.08)',
                                        },
                                        transition: 'all 0.2s ease-in-out',
                                        textTransform: 'none',
                                        fontSize: '1rem',
                                        fontWeight: 500
                                    }}
                                >
                                    Close
                                </Button>
                            </Box>
                        </Dialog>
                    </>
                )}
                <SocialMediaIcons profile={profile} />
                <button
                    style={{
                        margin: '20px',
                        padding: '10px 20px',
                        background: '#EF6191',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                    onClick={() => setShowMessagePopup(true)}
                >
                    Send Message
                </button>
            </div>
            <br/>
            <div align='center'>
                <div style={{
                    height: '70vh',
                    width: '100%',
                    maxWidth: '1000px',
                    margin: '0 auto',
                }} className="responsive-calendar">
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{width: '100%', height: '100%'}}
                        selectable
                        onSelectEvent={handleDateClick}
                        eventPropGetter={eventStyleGetter}
                        components={{
                            event: EventComponent
                        }}
                        defaultDate={new Date()}
                    />
                </div>

                {showContractPopup && profile.type === 'vendor' && (
                    <ArtistBookingBidPopup
                        booking={selectedData}
                        onClose={handleCloseContractPopup}
                    />
                )}
                {showBookedArtist && profile.type === 'vendor' && (
                    <div>
                        <Dialog onClose={ArtistNameHandleClose} open={showBookedArtist}>
                            <DialogTitle>This event is already booked with {artistName}</DialogTitle>
                        </Dialog>
                    </div>
                )}
                <Dialog open={showMessagePopup} onClose={() => setShowMessagePopup(false)}>
                    <DialogTitle>Send a Message</DialogTitle>
                    <div style={{padding: '20px', display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <textarea
                            style={{
                                width: '100%',
                                height: '100px',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #ccc'
                            }}
                            placeholder="Type your message here..."
                            value={messageContent}
                            onChange={(e) => setMessageContent(e.target.value)}
                        />
                        <button
                            style={{
                                padding: '10px 20px',
                                background: '#EF6191',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }}
                            onClick={async () => {
                                try {
                                    const response = await axios.post('https://api.live-and-local.com/api/send-message', {
                                        content: messageContent,
                                        receiverId: profile_id,
                                    }, {withCredentials: true});

                                    alert('Message sent successfully!');
                                    setMessageContent("");
                                    setShowMessagePopup(false);
                                } catch (error) {
                                    console.error('Error sending message:', error);
                                    alert('Failed to send the message. Please try again.');
                                }
                            }}
                        >
                            Send
                        </button>
                    </div>
                </Dialog>
        </div>
</div>
)
    ;
}

export default ViewProfile;